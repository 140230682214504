//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.headlineMedium
{
    font-family:        $fontHKGrotesk;
    font-size:          20px;
    font-weight:        $fontWeightBold;
    font-stretch:       normal;
    font-style:         normal;
    line-height:        1.25;
    letter-spacing:     normal;
    color:              $colorBlackLight;
    margin-bottom:      10px;
    word-break:         break-all;
    display:            -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow:           hidden;

    &.alignCenter
    {
        text-align: center;
    }

    &.alignLeft
    {
        text-align: left;
    }

    &.alignRight
    {
        text-align: right;
    }
}
