//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../styles/all';

.myProfileVerificationContainer
{
    display:          flex;
    flex-flow:        row nowrap;
    background-color: $colorWhite;
    margin-bottom:    30px;

    .myProfileVerificationContent
    {
        flex:    1;
        padding: 23px 25px;

        button
        {
            max-width: 200px;
        }

        .myProfileVerificationContentText
        {
            font-size: 14px;
            color:     $colorGrayDarker;

            > ul
            {
                list-style-type: none;
                counter-reset:   item;

                > li
                {
                    counter-increment: item;
                    padding-left:      1.5em;
                }

                > li::before
                {
                    content: counter(item) ". ";
                }
            }
        }

        .myProfileVerificationContentFooterText
        {
            font-size: 14px;
            color:     $colorGrayDarker;
        }
    }
}
