//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.tagSearchEditorHeaderContainer
{
    h2
    {
        margin-bottom: 15px;
    }
}

.tagSearchEditorBodyContainer
{
    margin-top: 55px;

    h3
    {
        margin-bottom: 15px;
    }

    .tagSearchEditorInputContainer
    {
        .tagSearchEditorInputWrapper
        {
            position:  relative;
            width:     320px;
            max-width: 100%;
            padding:   20px;

            &.tagSearchEditorInputWrapperWithResults
            {
                & + .tagSearchEditorResultWrapper
                {
                    margin:     0 20px 20px 20px;
                    border:     1px solid $colorGray;
                    max-height: 290px;
                }
            }

            input
            {
                color:         $colorBlack;
                width:         100%;
                padding-right: 35px;
            }

            .tagSearchEditorInputIcon
            {
                position:  absolute;
                top:       50%;
                right:     30px;
                width:     17px;
                height:    17px;
                transform: translateY(-50%);

                svg
                {
                    *
                    {
                        @include svgColorFill($colorGrayHeather);
                    }
                }
            }

            .tagSearchEditorAbortSearch
            {
                position:  absolute;
                top:       50%;
                right:     -10px;
                transform: translateY(-50%);

                > div
                {
                    width:      15px;
                    height:     15px;
                    min-width:  15px;
                    min-height: 15px;
                }

                svg
                {
                    *
                    {
                        @include svgColorFill($colorGrayHeather);
                    }
                }
            }
        }

        .tagSearchEditorResultWrapper
        {
            margin:     0;
            border:     0;
            max-height: 0;
            overflow-y: auto;
            transition: margin 0.1s linear,
                        border 0.1s linear,
                        max-height 0.1s linear;
        }

        .tagSearchEditorInputInnerContainer
        {
            display:     flex;
            flex-flow:   row wrap;
            align-items: flex-end;
            margin-top:  20px;

            &:first-child
            {
                margin-top: 0;
            }

            > *
            {
                flex: 1 1 200px;

                &:nth-child(n+2)
                {
                    margin-left: 20px;
                }

                &:last-child
                {
                    flex:          0;
                    margin-bottom: 10px;

                    svg
                    {
                        width:  10px;
                        height: 10px;
                    }
                }
            }
        }
    }
}

.tagInfoIcon
{
    display:          inline-block;
    width:            22px;
    height:           22px;
    background-image: url('../../../../assets/icons/info.svg');
    background-size:  contain;
}
