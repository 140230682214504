//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.membershipOption
{
    flex:             1;
    color:            $colorBlackLight;
    display:          flex;
    flex-flow:        column;
    align-items:      center;
    gap:              15px;
    min-width:        200px;
    padding:          30px 35px 40px 35px;
    border-radius:    2px;
    background-color: $colorWhite;

    &.membershipOptionHighlighted
    {
        color:      $colorWhite;
        background: linear-gradient(to bottom, $colorBlueCalypso 0%, $colorBlueNileBlue 100%);

        h2
        {
            span
            {
                &:nth-child(even)
                {
                    color: $colorOrange;
                }
            }
        }

        p.membershipOptionPrice
        {
            span
            {
                &::before,
                &::after
                {
                    color: $colorWhite;
                }
            }
        }

        ul
        {
            li
            {
                color: $colorWhite;
            }
        }
    }

    h2
    {
        font-size:   25px;
        font-weight: bold;
        line-height: 1.2;

        span
        {
            &::after
            {
                content: ' ';
            }
        }
    }

    p.membershipOptionPrice
    {
        font-size:   55px;
        font-weight: bold;
        line-height: 1.27;
        color:       $colorOrange;

        span
        {
            position: relative;

            &::before,
            &::after
            {
                font-size:   14px;
                line-height: 1;
                color:       $colorGrayDark;
            }

            &::before
            {
                content:   '€';
                position:  absolute;
                top:       0;
                left:      -5px;
                transform: translateY(100%) translateX(-100%);
            }

            &::after
            {
                content: '/yr';
            }
        }
    }

    a,
    button
    {
        margin-top:    8px;
        margin-bottom: 25px;
    }

    ul
    {
        display:    flex;
        flex-flow:  column;
        gap:        15px;
        list-style: none;

        li
        {
            position:       relative;
            font-size:      14px;
            font-weight:    800;
            line-height:    1.43;
            letter-spacing: 0.5px;
            padding-left:   30px;
            color:          $colorBlackLight;
            text-transform: uppercase;

            &::before
            {
                content:          '';
                position:         absolute;
                top:              0;
                left:             0;
                display:          block;
                width:            20px;
                height:           20px;
                background-size:  contain;
                background-image: url('/assets/icons/check-circle.svg');
            }

            &:hover
            {
                cursor:          default;
                text-decoration: underline;
            }
        }
    }
}

.tooltip
{
    font-size:      14px;
    line-height:    1.43;
    font-weight:    normal;
    text-align:     center;
    text-transform: none;

    strong
    {
        display:        block;
        margin-bottom:  6px;
        text-transform: uppercase;
    }
}
