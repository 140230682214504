//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.companyCreateBox
{
    display:         flex;
    flex-direction:  row;
    align-items:     center;
    justify-content: flex-start;

    > *
    {
        max-width:  33%;
        margin-top: 0 !important;

        &:not(:last-child)
        {
            margin-right: 20px;
        }
    }
}

.footerButtonContainer
{
    display:         flex;
    flex-direction:  row;
    justify-content: flex-start;
    column-gap:      10px;
}
