//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.tagCertificateFileInput
{
    margin-top: 10px;

    > input
    {
        display: none;
    }

    .tagCertificateFile
    {
        display:     flex;
        gap:         10px;
        align-items: center;

        .tagCertificateFileInformation
        {
            max-width:   300px;
            margin-left: 1px;
            cursor:      pointer;

            div:first-child
            {
                font-weight:   $fontWeightBold;
                text-overflow: ellipsis;
                overflow:      hidden;
                white-space:   nowrap;
            }

            div:last-child
            {
                color:     $colorGrayHeather;
                font-size: 12px;
            }
        }
    }
}
