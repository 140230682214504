//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.sideMenuWrapper
{
    flex:          1;
    width:         100%;
    background:    $colorWhite;
    padding:       22px 11px 11px 11px;
    border-radius: 2px;
    box-sizing:    border-box;

    @media (min-width: $screen-md)
    {
        flex:  inherit;
        width: 260px;
    }
}
