//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.inputContainer
{
    position: relative;

    .inputContainerGrowing
    {
        display: grid;

        &::after
        {
            content:     attr(data-replicated-value) " ";
            white-space: pre-wrap;
            visibility:  hidden;
        }

        > textarea
        {
            resize:   none;
            overflow: hidden;
        }

        > textarea,
        &::after
        {
            border:    1px solid black;
            padding:   20px 21px;
            font:      inherit;
            grid-area: 1 / 1 / 2 / 2;
        }
    }

    ::placeholder,
    :-ms-input-placeholder,
    ::-ms-input-placeholder
    {
        font-family:    $fontSourceOpenSans;
        font-size:      14px;
        font-weight:    $fontWeightRegular;
        font-stretch:   normal;
        font-style:     normal;
        line-height:    1.43;
        letter-spacing: normal;
        color:          $colorGrayDark;
    }

    .icon
    {
        position:  absolute;
        top:       50%;
        right:     15px;
        width:     15px;
        height:    15px;
        transform: translateY(-50%);
        color:     $colorGray;
    }
}

.textInput
{
    width:          100%;
    box-sizing:     border-box;
    padding:        9px 15px;
    border-radius:  5px;
    border:         1px solid $colorGray;
    font-family:    $fontSourceOpenSans;
    font-size:      14px;
    font-weight:    $fontWeightRegular;
    font-stretch:   normal;
    font-style:     normal;
    line-height:    1.43;
    letter-spacing: normal;
    color:          $colorBlack;

    + input,
    + textarea,
    {
        margin-top: 19px;
    }

    &.textInputMultiline,
    &.textInputMultilineLarge,
    &.textInputMultilineExtraLarge
    {
        resize:    vertical;
        max-width: 100%;
        min-width: 100%;
        padding:   20px 21px;
    }

    &.textInputMultiline
    {
        min-height: 100px;
    }

    &.textInputMultilineLarge
    {
        min-height: 120px;
    }

    &.textInputMultilineExtraLarge
    {
        min-height: 130px;
    }
}
