//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.colorBoxWrapper
{
    border-radius: 10px;
    padding:       20px;
}

.companyWrapper
{
    margin-bottom: 20px;
}

.companyLabel
{
    text-align: left;
}

.messageInput
{
    border:     none;
    padding:    0;
    background: transparent;
    color:      $colorGrayDark;
}
