//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.machineHeaderWrapper
{
    display:          flex;
    flex-flow:        column;
    background-color: $colorWhite;

    @media (min-width: $screen-md)
    {
        flex-flow: row nowrap;
    }

    .imageAndCapacitiesWrapper
    {
        display:        flex;
        flex-direction: column;
    }

    img
    {
        width:         120px;
        height:        120px;
        border:        solid 1px $colorGray;
        padding:       5px;
        object-fit:    contain;
        box-sizing:    border-box;
        border-radius: 2px;
        margin-bottom: 10px;
    }

    .capacities
    {
        background-color: $colorGrayLight;
        padding:          20px;
        box-sizing:       border-box;

        .title
        {
            font-weight:    $fontWeightExtraBold;
            color:          $colorGrayDarker;
            text-transform: uppercase;
        }

        .subtitle
        {
            margin:         15px 0 5px 0;
            font-weight:    $fontWeightLight;
            color:          $colorGrayDark;
            text-transform: lowercase;
        }

        .capacity
        {
            display:     flex;
            align-items: baseline;

            .year
            {
                font-weight: $fontWeightBold;
                margin-left: 5px;
            }

            .capacityDot
            {
                width:            10px;
                height:           10px;
                border-radius:    50%;
                background-color: yellow;
            }
        }
    }

    .machineHeaderTextWrapper
    {
        flex:           1;
        font-family:    $fontSourceOpenSans;
        font-size:      14px;
        font-weight:    $fontWeightRegular;
        font-stretch:   normal;
        font-style:     normal;
        line-height:    1.43;
        letter-spacing: normal;
        padding-top:    10px;
        color:          $colorGrayDarker;

        @media (min-width: $screen-md)
        {
            margin-left: 23px;
        }

        .machineHeaderTopWrapper
        {
            display:         flex;
            flex-flow:       row nowrap;
            align-items:     flex-start;
            justify-content: space-between;

            .machineHeaderTitleWrapper
            {
                display:   flex;
                flex-flow: column;

                h2
                {
                    word-break: break-word;

                    @media (max-width: $screen-sm-max)
                    {
                        width: 200%;
                    }
                }
            }

            .machineHeaderButtonWrapper
            {
                display:    flex;
                flex-flow:  row nowrap;
                margin-top: -130px;

                @media (min-width: $screen-md)
                {
                    margin-top: 0;
                }

                > *
                {
                    margin-left: 10px;
                }
            }
        }

        .machineHeaderText
        {
            margin-top: 11px;
        }
    }
}
