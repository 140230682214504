//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.informationBox
{
    width:            100%;
    border-radius:    10px;
    border:           solid 1px $colorDodgerBlue;
    background-color: $colorWhite;
    padding:          30px 30px 30px 29px;
    display:          flex;
    flex-direction:   row;
    justify-content:  space-between;
    gap:              20px;
    box-sizing:       border-box;

    &.column
    {
        flex-direction:  column;
        align-items:     flex-start;
        justify-content: flex-start;

        .informationBoxTextWrapper
        {
            align-self: flex-start;
        }

        .informationBoxButton
        {
            align-self: flex-start;
        }
    }

    .informationBoxTextWrapper
    {
        align-self:     center;
        display:        flex;
        flex-direction: column;
        gap:            6px;
        font-family:    $fontSourceOpenSans;
        font-size:      14px;
        line-height:    1.43;

        .informationBoxHeadline
        {
            text-transform: uppercase;
            font-weight:    $fontWeightExtraBold;
            color:          $colorDodgerBlue;
        }

        .informationBoxContent
        {
            color: $colorGrayDarker;
        }
    }

    .informationBoxButton
    {
        align-self: center;
        display:    flex;
        gap:        20px;
    }
}
