//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.editFactSheetFactsWrapper
{
    margin:  auto;
    padding: 24px 20px 20px;

    .editFactSheetFactsRow
    {
        width:      100%;
        padding:    24px 20px 20px;
        box-sizing: border-box;

        > *,
        > * > *,
        input,
        textarea
        {
            width: 100%;
        }

        @media (min-width: $screen-md)
        {
            width: 50%;
        }
    }

    .editFactSheetFactsTagWrapper
    {
        > h3
        {
            margin-top:    20px;
            margin-bottom: 15px;
        }

        .editFactSheetFactsTagRow
        {
            display:       flex;
            flex-flow:     row;
            align-content: center;
            margin-top:    15px;

            > div
            {
                align-self: center;
                color:      $colorGrayHeather;
                width:      100%;

                @media (min-width: $screen-md)
                {
                    width: 50%;
                }

                &:last-child
                {
                    width:       auto;
                    margin-left: 20px;
                }
            }
        }

        .editFactSheetFactsExpirableTagCertificate
        {
            display:    flex;
            flex-flow:  column;
            margin-top: 15px;

            .editFactSheetFactsExpirableTagCertificateRow
            {
                width:       90%;
                display:     flex;
                gap:         20px;
                align-items: center;

                > div
                {
                    flex: 1;

                    &:last-child
                    {
                        flex: 0 1 1%;
                    }
                }
            }
        }

        .certificateFileMemberHint
        {
            margin-bottom: 20px;
        }

        .editFactSheetFactsTagEmptyLabel
        {
            color:     $colorGrayDarker;
            font-size: 14px;
        }
    }

    .editFactSheetFactsInputWrapper
    {
        display:       flex;
        flex:          0 1 50%;
        flex-flow:     column;
        align-items:   flex-start;
        align-content: center;
    }
}
