//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.editCompanyHeaderContainer
{
    display:        flex;
    flex-flow:      row nowrap;
    padding-bottom: 15px;

    .editCompanyHeaderWrapper
    {
        flex:      1;
        display:   flex;
        flex-flow: column;

        @media (min-width: $screen-md)
        {
            flex-flow: row nowrap;
        }

        .editCompanyHeaderCompanyTypeWrapper
        {
            flex:      0 1 50%;
            display:   flex;
            flex-flow: column;
            max-width: 370px;

            h2
            {
                font-size:      21px;
                line-height:    1.43;
                font-family:    $fontSourceOpenSans;
                font-weight:    $fontWeightExtraBold;
                font-stretch:   normal;
                font-style:     normal;
                letter-spacing: normal;
                color:          $colorBlackLight;
                overflow:       hidden;
                text-overflow:  ellipsis;
            }

            > div:last-child
            {
                @media (max-width: $screen-sm-max)
                {
                    margin-left: -85px;
                }
            }
        }

        .editCompanyHeaderCountryWrapper
        {
            flex:        0 1 50%;
            display:     flex;
            flex-flow:   column;
            margin-left: -85px;

            @media (min-width: $screen-md)
            {
                margin-left: 35px;
            }

            h3
            {
                height:      30px;
                display:     flex;
                align-items: flex-end;
            }

            input
            {
                min-height: 42px;
            }
        }

        .editCompanyHeaderInfoText
        {
            display:     flex;
            align-items: flex-end;
            height:      26px;
            font-family: $fontSourceOpenSans;
            font-size:   12px;
            font-weight: $fontWeightRegular;
            line-height: 1.25;
            color:       $colorGrayDark;
            white-space: nowrap;
            margin-left: -85px;

            @media (min-width: $screen-md)
            {
                width:           0;
                margin-left:     0;
                justify-content: flex-end;
            }
        }
    }
}

.editCompanyBodyContainer
{
    border-top:  1px solid $colorGray;
    padding-top: 25px;

    h3
    {
        margin-bottom: 5px;

        &:not(:first-of-type)
        {
            margin-top:    55px;
            margin-bottom: 15px;
        }
    }

    .editCompanyTagInputContainer
    {
        .editCompanyTagInputWrapper
        {
            position:  relative;
            width:     320px;
            max-width: 100%;
            padding:   20px;

            &.editCompanyTagInputWrapperWithResults
            {
                & + .editCompanyTagResultWrapper
                {
                    margin:     0 20px 20px 20px;
                    border:     1px solid $colorGray;
                    max-height: 290px;
                }
            }

            input
            {
                color:         $colorBlack;
                width:         100%;
                padding-right: 35px;
            }

            .editCompanyTagInputIcon
            {
                position:  absolute;
                top:       50%;
                right:     30px;
                width:     17px;
                height:    17px;
                transform: translateY(-50%);

                svg
                {
                    *
                    {
                        @include svgColorFill($colorGrayHeather);
                    }
                }
            }

            .editCompanyTagAbortSearch
            {
                position:  absolute;
                top:       50%;
                right:     -10px;
                transform: translateY(-50%);

                > div
                {
                    width:      15px;
                    height:     15px;
                    min-width:  15px;
                    min-height: 15px;
                }

                svg
                {
                    *
                    {
                        @include svgColorFill($colorGrayHeather);
                    }
                }
            }
        }

        .editCompanyTagResultWrapper
        {
            margin:     0;
            border:     0;
            max-height: 0;
            overflow-y: auto;
            transition: margin 0.1s linear,
                        border 0.1s linear,
                        max-height 0.1s linear;
        }
    }

    .editCompanyBodyAddressFormRow
    {
        display:   flex;
        flex-flow: row nowrap;

        &:not(:first-child)
        {
            margin-top: 15px;
        }

        > *
        {
            flex:      1;
            max-width: 320px;

            &:first-child
            {
                margin-right: 20px;
            }
        }
    }

    .editCompanyDangerZone
    {
        margin-top: 30px;
    }
}

.editCompanyLanguageOption
{
    display:     flex;
    flex-flow:   row nowrap;
    align-items: center;

    > span
    {
        margin-right: 12px;
    }
}

.editCompanyDropDownInfoIcon
{
    display:          inline-block;
    width:            22px;
    height:           22px;
    background-image: url('../../../../assets/icons/info.svg');
    background-size:  contain;
}
