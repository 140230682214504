//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.tableHeaderFilterRow
{
    background-color: $colorWhite;

    @media (max-width: $screen-sm-max)
    {
        display:   flex;
        flex-flow: column;

        th
        {
            div
            {
                padding:     10px 0 !important;
                margin-left: 15px !important;
            }

            &:nth-child(n+3)
            {
                display: none;
            }
        }
    }
}
