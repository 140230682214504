//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.wrapper
{
    background-color: $colorGrayLight;
    padding:          15px;
    display:          flex;
    gap:              30px;
    flex-direction:   row;
    flex:             1;

    .right
    {
        display:       flex;
        flex-flow:     row wrap;
        align-items:   center;
        gap:           10px;
        margin-bottom: auto;

        h3
        {
            flex: 0 0 100%;
        }
    }

    .iconWrapper
    {
        width:  32px;
        height: 32px;
        color:  $colorGrayDark;
    }

    .deleteCircleWrapper
    {
        width:  22px;
        height: 22px;
        color:  $colorBlack40;
    }

    h3
    {
        font-size:      14px;
        font-weight:    $fontWeightExtraBold;
        padding-bottom: 10px;
        text-transform: uppercase;
    }

    .fileEntry
    {
        padding-bottom:  5px;
        color:           $colorGrayDark;
        display:         flex;
        flex-direction:  row;
        gap:             10px;
        align-content:   center;
        justify-content: space-between;
    }

    form
    {
        text-align: center;
        position:   relative;
    }

    input[type="file"]
    {
        display: none;
    }

    .label
    {
        height:           128px;
        width:            128px;
        display:          flex;
        align-items:      center;
        justify-content:  center;
        flex-direction:   column;
        border-width:     1px;
        border-radius:    5px;
        border-style:     solid;
        border-color:     $colorBlack30;
        background-color: $colorGrayLight;
    }

    label.dragActive
    {
        background-color: $colorWhite;
    }

    .uploadButton
    {
        cursor:           pointer;
        padding:          3px 20px;
        font-size:        12px;
        border:           none;
        background-color: transparent;
        text-align:       center;
        color:            $colorGrayDark;
    }

    .dragFileElement
    {
        position:      absolute;
        width:         100%;
        height:        100%;
        border-radius: 1rem;
        top:           0;
        right:         0;
        bottom:        0;
        left:          0;
    }
}

.hideFileInput
{
    display: none;
}

.renderOnlyAttachmentsFileEntry
{
    padding-bottom:  5px;
    color:           #939699;
    display:         flex;
    flex-direction:  row;
    grid-gap:        10px;
    gap:             10px;
    align-content:   center;
    justify-content: flex-start;
    align-items:     center;
}
