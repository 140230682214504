//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.machineSearchResultWrapper
{
    height:      41px;
    position:    relative;
    padding:     0 0 0 16px;
    font-family: $fontSourceOpenSans;
    font-weight: $fontWeightBold;
    font-size:   14px;
    display:     flex;
    align-items: center;

    img
    {
        width:        25px;
        height:       25px;
        margin-right: 15px;
    }
}
