//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../styles/all';

.myProfileContainer
{
    display:          flex;
    flex-flow:        row nowrap;
    background-color: $colorWhite;

    .myProfileContent
    {
        flex:    1;
        padding: 23px 25px;

        .myProfileDescription
        {
            margin-bottom: 35px;
        }

        h4
        {
            text-transform: uppercase;
        }
    }

    p
    {
        font-family: $fontSourceOpenSans;
        font-size:   14px;
        font-weight: 400;
        line-height: 1.43;
        color:       $colorGrayDarker;
        margin-top:  10px;
    }
}

.languageOption
{
    display:     flex;
    flex-flow:   row nowrap;
    align-items: center;

    > span
    {
        margin-right: 12px;
    }
}

.profileHeaderContainer
{
    display:        flex;
    flex-flow:      row nowrap;
    padding-bottom: 15px;

    .profileHeaderWrapper
    {
        flex:      1;
        display:   flex;
        flex-flow: row nowrap;

        .profileHeaderCompanyTypeWrapper
        {
            flex:      0 1 50%;
            display:   flex;
            flex-flow: column;
            max-width: 370px;

            h2
            {
                font-size:      21px;
                line-height:    1.43;
                font-family:    $fontSourceOpenSans;
                font-weight:    $fontWeightExtraBold;
                font-stretch:   normal;
                font-style:     normal;
                letter-spacing: normal;
                color:          $colorBlackLight;
                overflow:       hidden;
                white-space:    nowrap;
                text-overflow:  ellipsis;
            }
        }

        .profileHeaderCountryWrapper
        {
            flex:        0 1 50%;
            display:     flex;
            flex-flow:   column;
            margin-left: 35px;

            h3
            {
                height:      30px;
                display:     flex;
                align-items: flex-end;
            }

            input
            {
                min-height: 42px;
            }
        }

        .profileHeaderInfoText
        {
            width:           0;
            display:         flex;
            align-items:     flex-end;
            justify-content: flex-end;
            height:          26px;
            font-family:     $fontSourceOpenSans;
            font-size:       12px;
            font-weight:     $fontWeightRegular;
            line-height:     1.25;
            color:           $colorGrayDark;
            white-space:     nowrap;
        }
    }
}

h3
{
    margin-bottom: 5px;

    &:not(:first-of-type)
    {
        margin-top:    55px;
        margin-bottom: 15px;
    }
}

.profileTagInputContainer
{
    display:        flex;
    flex-flow:      row nowrap;
    flex-direction: column;

    .profileTagInputWrapper
    {
        position:  relative;
        max-width: 100%;

        &.profileTagInputWrapperWithResults
        {
            & + .profileTagResultWrapper
            {
                border:     1px solid $colorGray;
                max-height: 290px;
            }
        }

        input
        {
            color:         $colorBlack;
            width:         100%;
            padding-right: 35px;
        }

        .profileTagInputIcon
        {
            position:  absolute;
            top:       50%;
            right:     30px;
            width:     17px;
            height:    17px;
            transform: translateY(-50%);

            svg
            {
                *
                {
                    @include svgColorFill($colorGrayHeather);
                }
            }
        }

        .profileTagAbortSearch
        {
            position:  absolute;
            top:       50%;
            right:     -10px;
            transform: translateY(-50%);

            > div
            {
                width:      15px;
                height:     15px;
                min-width:  15px;
                min-height: 15px;
            }

            svg
            {
                *
                {
                    @include svgColorFill($colorGrayHeather);
                }
            }
        }
    }

    .profileTagResultWrapper
    {
        margin:     0;
        border:     0;
        max-height: 0;
        overflow-y: auto;
        transition: margin 0.1s linear,
                    border 0.1s linear,
                    max-height 0.1s linear;
    }
}

.profileBodyAddressFormRow
{
    display:   flex;
    flex-flow: row nowrap;

    &:not(:first-child)
    {
        margin-top: 15px;
    }

    > *
    {
        flex:      1;
        max-width: 320px;

        &:first-child
        {
            margin-right: 20px;
        }
    }
}

.profileLanguageOption
{
    display:     flex;
    flex-flow:   row nowrap;
    align-items: center;

    > span
    {
        margin-right: 12px;
    }
}

.profileDropDownInfoIcon
{
    display:          inline-block;
    width:            22px;
    height:           22px;
    background-image: url('../../assets/icons/info.svg');
    background-size:  contain;
}
