//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.customValuesContainer
{
    display:    flex;
    flex-flow:  column;
    margin-top: 15px;

    .customValuesInnerContainer
    {
        display:    flex;
        flex-flow:  row wrap;
        margin-top: 5px;
    }
}

// These values are copied from react-select
.customValue
{
    border-radius:    2px;
    display:          flex;
    margin:           2px;
    min-width:        0;
    box-sizing:       border-box;
    background-color: $colorMercury;

    span
    {
        border-radius: 2px;
        color:         $colorMineShaft;
        font-size:     85%;
        overflow:      hidden;
        padding:       3px 3px 3px 6px;
        text-overflow: ellipsis;
        white-space:   nowrap;
        box-sizing:    border-box;
    }

    button
    {
        cursor:        pointer;
        width:         auto;
        align-items:   center;
        border-radius: 2px;
        display:       flex;
        padding-left:  4px;
        padding-right: 4px;
        box-sizing:    border-box;

        &:hover
        {
            color:            $colorRedFireEngineRed;
            background-color: $colorRedRoseBud;
        }

        svg
        {
            path
            {
                @include svgColorFill($colorBlack);
            }
        }
    }
}

.dropDownInput
{

}

.dropDownOption
{
    font-size:   14px;
    font-family: $fontSourceOpenSans;
    display:     flex;
    align-items: center;
    height:      40px;
    padding:     0 10px 0 18px;
    border-top:  1px solid $colorGray;
    cursor:      pointer;
    background:  $colorWhite;
    color:       $colorGrayDarker !important;
    transition:  background-color linear 0.1s;
    position:    relative;

    &:hover
    {
        background:       $colorOrangeLight;
        border-top-color: $colorOrange;

        &::after
        {
            height:         1px;
            display:        block;
            width:          100%;
            background:     $colorOrange;
            content:        '';
            position:       absolute;
            bottom:         -1px;
            left:           0;
            pointer-events: none;
            z-index:        1337;
        }

        &:last-child
        {
            &::after
            {
                display: none;
            }
        }
    }

    &.dropDownOptionHierarchicalHeadline
    {
        font-weight: bold;
    }

    &.dropDownOptionHierarchicalChild
    {
        padding-left: 15px;
    }

    .dropDownOptionIconWrapper
    {
        margin: 0 5px 0 0;
    }
}

.dropDownIndicatorWrapper
{
    padding: 0 18px 0 0;

    &.dropDownIndicatorWrapperMenuIsOpen
    {
        > span
        {
            transform: rotate(180deg);
        }
    }

    svg
    {
        width: 12px;

        *
        {
            @include svgColorFillImportant($colorGrayDark) ;
        }
    }
}

.noOptionsMessageWrapper
{
    color:       $colorGrayDarker !important;
    font-family: $fontSourceOpenSans;
    font-size:   14px;
    text-align:  center;
    padding:     10px 5px;
    background:  $colorWhite;
}

.valueWrapper
{
    align-items:    center;
    color:          $colorGrayDarker;
    display:        flex;
    font-family:    $fontSourceOpenSans;
    font-size:      14px;
    padding-top:    0;
    padding-right:  0;
    padding-bottom: 4px;
    padding-left:   4px;
}
