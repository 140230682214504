//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.entityLabelWrapper
{
    display:     flex;
    flex-flow:   row nowrap;
    align-items: center;

    &.entityLabelWrapperDefault
    {
        .entityLabelAvatarContainer
        {
            margin-right: 10px;

            a
            {
                width:  68px;
                height: 68px;
            }
        }

        .entityLabelTextContainer
        {
            padding: 6px 0;

            @media (min-width: $screen-md)
            {
                margin-left:  15px;
                margin-right: 0;
            }

            .entityLabelTextTitle
            {
                h2
                {
                    font-size:     21px;
                    margin-bottom: 0;
                }

                .entityLabelTextFlag
                {
                    height:     100%;
                    align-self: flex-start;
                    padding:    6px;
                }

                .entityLabelTextVerified
                {
                    cursor:       pointer;
                    font-size:    12px;
                    padding-left: 15px;
                    margin-right: 20px;
                }
            }

            .entityLabelTextSubtitle
            {
                font-size: 18px;
            }
        }
    }

    &.entityLabelWrapperSmall
    {
        min-height: 40px;

        .entityLabelAvatarContainer
        {
            width:        40px;
            aspect-ratio: 1 / 1;
        }

        .entityLabelTextContainer
        {
            padding:     4px 0;
            margin-left: 10px;

            .entityLabelTextTitle
            {
                h2
                {
                    min-width:     100px;
                    font-size:     14px;
                    margin-bottom: 0;
                }

                .entityLabelTextVerified
                {
                    font-size:    10px;
                    padding-left: 10px;

                    &::before
                    {
                        width:  15px;
                        height: 15px;
                    }
                }
            }

            .entityLabelTextSubtitle
            {
                font-size: 12px;
            }
        }
    }

    .entityLabelTextContainer
    {
        display:         flex;
        flex-flow:       column;
        justify-content: space-between;
        padding:         0 6px;
        width:           100%;

        @media (min-width: $screen-md)
        {
            margin-left: 15px;
        }

        .entityLabelTextTitle
        {
            position:        relative;
            display:         flex;
            flex-flow:       row nowrap;
            align-items:     center;
            justify-content: space-between;
            gap:             7px;
            width:           100%;

            .headline
            {
                margin-bottom: 0;
            }

            h2
            {
                font-size:      21px;
                line-height:    1.43;
                font-family:    $fontSourceOpenSans;
                font-weight:    $fontWeightExtraBold;
                font-stretch:   normal;
                font-style:     normal;
                letter-spacing: normal;
                word-break:     normal;
                color:          $colorBlackLight;
            }

            .entityLinkVerifiedContainer
            {
                display:     flex;
                align-items: center;
            }

            .entityLabelTextFlag
            {
                height:     100%;
                align-self: flex-start;
                padding:    6px;
            }

            .entityLabelTextVerified,
            .entityLabelTextUnderVerification
            {
                position:      relative;
                font-family:   $fontSourceOpenSans;
                font-weight:   $fontWeightBold;
                line-height:   1.2;
                padding-right: 3px;
                border-radius: 3px;
                margin-left:   5px;

                @media (min-width: $screen-md)
                {
                    margin-left: 15px;
                }

                span
                {
                    position:  absolute;
                    width:     16px;
                    height:    16px;
                    top:       50%;
                    right:     -20px;
                    transform: translateY(-50%);

                    svg
                    {
                        path
                        {
                            @include svgColorFillImportant($colorGrayDark1);
                        }
                    }
                }
            }

            .entityLabelTextVerified
            {
                background-color: $colorOrangeLight;

                &::before
                {
                    content:          '';
                    position:         absolute;
                    top:              50%;
                    left:             -7px;
                    width:            20px;
                    height:           20px;
                    transform:        translateY(-50%);
                    background-image: url('../../../../assets/icons/verified.svg');
                    background-size:  contain;
                }
            }

            .entityLabelTextUnderVerification
            {
                color:            $colorBlackLight;
                font-size:        12px;
                padding-left:     15px;
                background-color: $colorGrayLight;

                &::before
                {
                    content:          '';
                    position:         absolute;
                    top:              50%;
                    left:             -7px;
                    width:            20px;
                    height:           20px;
                    transform:        translateY(-50%);
                    background-image: url('../../../../assets/icons/clock.svg');
                    background-size:  contain;
                }
            }

            .labelContainer
            {
                display:         flex;
                flex-flow:       row wrap;
                align-items:     center;
                justify-content: flex-end;
                gap:             5px;
            }
        }

        .entityLabelTextSubtitle
        {
            font-size:      18px;
            line-height:    1.25;
            font-family:    $fontSourceOpenSans;
            font-weight:    $fontWeightRegular;
            font-stretch:   normal;
            font-style:     normal;
            letter-spacing: normal;
            color:          $colorGrayDark;
        }
    }

    &.entityLabelWrapperWhite
    {
        h2,
        .entityLabelTextSubtitle
        {
            color: $colorWhite !important;
        }
    }
}

.tooltip
{
    display:     flex;
    flex-flow:   column;
    align-items: center;
    padding:     0;

    strong
    {
        font-size:      14px;
        font-weight:    $fontWeightBold;
        text-transform: uppercase;
    }

    button
    {
        color:         $colorWhite !important;
        padding:       4px 10px !important;
        line-height:   1 !important;
        border-radius: 5px;
        flex-flow:     row-reverse nowrap;
        margin-top:    11px;

        > div
        {
            width:        24px !important;
            height:       24px !important;
            margin-left:  0 !important;
            margin-right: 10px;
        }
    }
}
