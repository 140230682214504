//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.paginationWrapper
{
    display:          flex;
    align-items:      center;
    justify-content:  center;
    width:            100%;
    padding:          15px;
    margin-top:       25px;
    background-color: $colorWhiteDark;
    list-style:       none;
    box-sizing:       border-box;

    .paginationNextPrev
    {
        height: 13px;

        > div
        {
            width:      100%;
            min-width:  100%;
            height:     100%;
            min-height: 100%;
        }

        &:hover
        {
            svg
            {
                *
                {
                    @include svgColorFill($colorOrange);
                }
            }
        }

        svg
        {
            *
            {
                @include svgColorFill($colorGrayHeather);

                transition: fill 0.1s linear;
            }
        }
    }

    .paginationNumber
    {
        display: flex;

        .paginationLink
        {
            font-family:     $fontSourceOpenSans;
            font-size:       14px;
            font-weight:     $fontWeightRegular;
            font-stretch:    normal;
            font-style:      normal;
            line-height:     1.07;
            letter-spacing:  normal;
            text-align:      center;
            color:           $colorGrayDark;
            text-decoration: none;
            padding:         0 7px;
            transition:      color 0.1s linear;

            &:hover
            {
                color: $colorOrange;
            }
        }
    }

    .paginationNumberActive
    {
        .paginationLink
        {
            font-size:   18px;
            font-weight: $fontWeightBold;
            color:       $colorOrange;
        }
    }

    button
    {
        cursor: pointer;
    }
}
