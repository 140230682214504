//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

$transitionDuration: 0.2s;

.tagSelectorWrapper
{
    padding:    20px 20px 6px 20px;
    max-height: 100%;

    &.clickable
    {
        cursor: pointer;
    }

    &.disabled
    {
        cursor:  not-allowed !important;
        opacity: 0.8 !important;
    }

    .stepDown
    {
        border-color:        $colorGray;
        border-style:        dashed;
        border-width:        0;
        border-bottom-width: 1px;
        width:               15px;
        max-width:           15px;
        height:              20px;
        margin:              0 10px 0 0;
        transition:          max-width 0.2s linear;

        &.stepDownHidden
        {
            max-width: 0;
        }
    }

    .tagWrapper
    {
        &.tagWrapperHidden
        {
            > .tagContent
            {
                opacity:    0;
                max-height: 0;
                transition: opacity $transitionDuration linear,
                            max-height $transitionDuration linear $transitionDuration;
            }
        }

        .tagContent
        {
            display:        flex;
            flex-direction: row;
            margin-bottom:  12px;
            align-items:    center;
            max-height:     80px;
            overflow:       hidden;
            opacity:        1;
            transition:     opacity $transitionDuration linear $transitionDuration,
                            max-height $transitionDuration linear;

            &:hover
            {
                > .tagHoverContent
                {
                    opacity: 1;
                }
            }

            &.tagContentAddButton
            {
                border-left: 1px solid $colorWhite;
                margin-left: -1px;
            }

            > button
            {
                width: auto;

                > span
                {
                    width:  15px;
                    height: 15px;
                }
            }

            .dropDownWrapper
            {
                min-width: 200px;
                margin:    0 6px 0 0;

                &.dropDownWrapperNotClickable
                {
                    pointer-events: none;
                }
            }

            .dropDownInfoIconWrapper
            {
                display:      flex;
                align-items:  center;
                margin-right: 6px;

                .dropDownInfoIcon
                {
                    display:          inline-block;
                    width:            22px;
                    height:           22px;
                    background-image: url('../../../../assets/icons/info.svg');
                    background-size:  contain;
                }
            }

            .tagHoverContent
            {
                display:     flex;
                align-items: center;
                transition:  opacity $transitionDuration linear;

                @media (min-width: $screen-md)
                {
                    opacity: 0;
                }

                > button
                {
                    > span
                    {
                        width:  15px;
                        height: 15px;
                    }
                }
            }
        }
    }

    .tagListWrapper
    {
        border-left: 1px dashed $colorGray;
        transition:  margin-left $transitionDuration linear;

        &.tagListWrapperLevel0
        {
            border-left: 0;
        }

        &.tagListWrapperReduced
        {
            display:     flex;
            flex-flow:   row wrap;
            border-left: 0;
            margin-left: 0 !important;

            > *
            {
                margin-right: 10px;
            }
        }
    }

    > .tagListWrapper
    {
        &:last-child
        {
            > .tagWrapper
            {
                &:last-child
                {
                    > .tagListWrapper
                    {
                        position: relative;

                        &::after
                        {
                            content:          '';
                            position:         absolute;
                            width:            1px;
                            height:           8px;
                            left:             -1px;
                            bottom:           0;
                            background-color: $colorWhite;
                        }
                    }
                }
            }
        }
    }

    .tip
    {
        font-size:  12px;
        color:      $colorGrayDark;
        margin:     0 0 7px 0;
        padding:    0 0 0 6px;
        max-height: 20px;
        overflow:   hidden;
        opacity:    1;
        transition: opacity $transitionDuration linear $transitionDuration,
                    max-height $transitionDuration linear;

        &.tipHidden
        {
            opacity:    0;
            max-height: 0;
            transition: opacity $transitionDuration linear,
                        max-height $transitionDuration linear $transitionDuration;
        }
    }

    .placeholder
    {
        font-size:     14px;
        color:         $colorGrayDark;
        margin-left:   -5px;
        margin-top:    -15px;
        padding:       5px 0;
        margin-bottom: 20px;
    }
}
