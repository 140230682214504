//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../styles/fontWeights';

@font-face
{
    font-family: 'Open Sans';
    font-style:  normal;
    font-weight: 400;
    src:         local(''),
                 url('../assets/fonts/OpenSans/OpenSans-400.woff2') format('woff2'),
                 url('../assets/fonts/OpenSans/OpenSans-400.woff') format('woff'),
                 url('../assets/fonts/OpenSans/OpenSans-400.ttf') format('truetype');
}

@font-face
{
    font-family: 'Open Sans';
    font-style:  normal;
    src:         local(''),
                 url('../assets/fonts/OpenSans/OpenSans-600.woff2') format('woff2'),
                 url('../assets/fonts/OpenSans/OpenSans-600.woff') format('woff'),
                 url('../assets/fonts/OpenSans/OpenSans-700.ttf') format('truetype');
}

@font-face
{
    font-family: 'Open Sans';
    font-style:  normal;
    font-weight: 700;
    src:         local(''),
                 url('../assets/fonts/OpenSans/OpenSans-700.woff2') format('woff2'),
                 url('../assets/fonts/OpenSans/OpenSans-700.woff') format('woff'),
                 url('../assets/fonts/OpenSans/OpenSans-700.ttf') format('truetype');
}

@font-face
{
    font-family: 'Open Sans';
    font-style:  normal;
    font-weight: 800;
    src:         local(''),
                 url('../assets/fonts/OpenSans/OpenSans-800.woff2') format('woff2'),
                 url('../assets/fonts/OpenSans/OpenSans-800.woff') format('woff'),
                 url('../assets/fonts/OpenSans/OpenSans-800.ttf') format('truetype');
}

@font-face
{
    font-family:  'HK Grotesk';
    src:          url('../assets/fonts/HKGrotesk/HKGrotesk-Bold.woff2') format('woff2'),
                  url('../assets/fonts/HKGrotesk/HKGrotesk-Bold.woff') format('woff'),
                  url('../assets/fonts/HKGrotesk/HKGrotesk-Bold.ttf') format('truetype');
    font-weight:  $fontWeightBold;
    font-style:   italic;
    font-display: fallback;
}

$fontHKGrotesk:        'HK Grotesk', sans-serif;
$fontSourceOpenSans:   'Open Sans', sans-serif;
$fontSourceCourierNew: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
