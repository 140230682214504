//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.textCurrencyInput
{
    width:          100%;
    box-sizing:     border-box;
    padding:        9px 15px;
    border-radius:  5px;
    border:         1px solid $colorGray;
    font-family:    $fontSourceOpenSans;
    font-size:      14px;
    font-weight:    $fontWeightRegular;
    font-stretch:   normal;
    font-style:     normal;
    line-height:    1.43;
    letter-spacing: normal;
    color:          $colorBlack;

    + input,
    + textarea,
    {
        margin-top: 19px;
    }

    &.textCurrencyInputMultiline
    {
        max-width: 100%;
        min-width: 100%;
        height:    100px;
        padding:   20px 21px;

        &.textCurrencyInputMultilineLarge
        {
            height: 120px;
        }

        &.textCurrencyInputMultilineExtraLarge
        {
            height: 130px;
        }
    }
}

::placeholder,
:-ms-input-placeholder,
::-ms-input-placeholder
{
    font-family:    $fontSourceOpenSans;
    font-size:      14px;
    font-weight:    $fontWeightRegular;
    font-stretch:   normal;
    font-style:     normal;
    line-height:    1.43;
    letter-spacing: normal;
    color:          $colorGrayDark;
}
