//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.bestMatchLabel
{
    font-family:      $fontSourceOpenSans;
    font-size:        10px;
    font-weight:      $fontWeightExtraBold;
    line-height:      1.25;
    color:            $colorWhite;
    padding:          2px 4.5px;
    border-radius:    3px;
    background-color: $colorOrange;
    white-space:      nowrap;
    align-self:       flex-start;

    @media (min-width: $screen-md)
    {
        font-size:   12px;
        margin-left: 13px;
    }
}
