//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../styles/all';

.homeContent
{
    flex:            1;
    position:        relative;
    z-index:         1;
    width:           1050px;
    max-width:       100%;
    height:          70%;
    display:         flex;
    flex-flow:       column;
    align-items:     center;
    justify-content: center;
    box-sizing:      border-box;
    margin:          0 auto;
    padding:         0 10px 140px 10px;

    @media (min-height: 800px)
    {
        margin: auto;
    }

    @media (max-width: $screen-sm-max) and (max-height: 1200px)
    {
        margin-top: 20px;
    }

    h1
    {
        font-family:    $fontHKGrotesk;
        font-size:      24px;
        font-weight:    $fontWeightBold;
        font-stretch:   normal;
        font-style:     normal;
        line-height:    1.07;
        letter-spacing: 0.23px;
        text-align:     center;
        white-space:    pre-line;
        color:          $colorWhite;
        margin-bottom:  20px;

        @media (min-width: $screen-xs)
        {
            font-size: 40px;
        }

        @media (min-width: $screen-sm)
        {
            font-size: 48px;
        }

        @media (min-width: $screen-md)
        {
            font-size: 56px;
        }

        @media (min-height: 800px)
        {
            margin-bottom: 40px;
        }

        span
        {
            color: $colorOrange;
        }
    }

    .homeContentCTA
    {
        display:      flex;
        flex-flow:    column;
        align-items:  center;
        white-space:  pre-line;
        margin-top:   17px;

        > span
        {
            opacity:        0.7;
            font-family:    $fontSourceOpenSans;
            font-size:      16px;
            font-weight:    $fontWeightRegular;
            font-stretch:   normal;
            font-style:     normal;
            line-height:    1.8;
            letter-spacing: normal;
            text-align:     center;
            color:          $colorWhite;
            margin-bottom:  15px;

            @media (min-width: $screen-md)
            {
                font-size: 20px;
            }
        }
    }

    .homePartner
    {
        position:      absolute;
        bottom:        0;
        display:       flex;
        flex-flow:     row nowrap;
        align-items:   flex-end;
        gap:           25px;
        margin-bottom: 115px;
    }
}
