//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.sideMenuButtonWrapper
{
    display:         flex;
    flex-flow:       row;
    align-content:   center;
    justify-content: flex-start;
    padding:         10px 14px;
    height:          40px;
    box-sizing:      border-box;
    transition:      background-color 0.1s linear;
    cursor:          pointer;
    text-decoration: none;
    color:           $colorOrange;

    &:hover
    {
        background: $colorOrangeLighter;
    }

    .sideMenuButtonTitle
    {
        font-family:    $fontSourceOpenSans;
        font-size:      9px;
        font-weight:    $fontWeightRegular;
        font-stretch:   normal;
        font-style:     normal;
        line-height:    20px;
        letter-spacing: normal;
        color:          $colorGrayDarker;
        transition:     color 0.1s linear;
    }

    .iconContainer
    {
        margin-left: 7px;
    }
}
