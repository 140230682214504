//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.checkBoxWrapper
{
    display:        flex;
    align-content:  center;
    flex-direction: column;
    margin:         0 0 20px 0;
}

.checkBoxWrapperClickArea
{
    cursor:         pointer;
    display:        flex;
    flex-direction: row;
}

.checkBoxWrapperLabel
{
    margin:      0 0 0 14px;
    user-select: none;
    font-size:   12px;
    line-height: 1.7;
    color:       $colorGrayDarker;
}

.checkBox
{
    width:         20px;
    height:        20px;
    border:        1px solid $colorGray;
    border-radius: 4px;
}

.checkBoxChecked
{
    border:           1px solid $colorOrange;
    background-color: $colorOrange;

    span
    {
        height: 100%;
    }

    svg
    {
        @include svgColorFill($colorGray);

        height: 100%;
        width:  60%;
        margin: 0 0 0 0;
    }
}
