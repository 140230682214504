//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.tabBarWrapper
{
    position:         relative;
    display:          flex;
    flex-flow:        column;
    box-sizing:       border-box;
    width:            100%;
    padding:          10px 0;
    background-color: $colorWhite;

    @media (min-width: $screen-md)
    {
        padding:   0 35px;
        flex-flow: row nowrap;
    }

    .tabBarIndicator
    {
        position:         absolute;
        top:              0;
        left:             0;
        height:           5px;
        background-color: $colorOrange;
        transition:       left 0.1s ease-out,
                          width 0.1s ease-out;
    }
}
