//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.sideMenuButtonWrapper
{
    display:         flex;
    flex-flow:       column;
    justify-content: center;
    padding:         10px 14px;
    box-sizing:      border-box;
    transition:      background-color 0.1s linear;
    cursor:          pointer;
    text-decoration: none;

    @media (min-width: $screen-md)
    {
        height: 55px;
    }

    &:hover,
    &.sideMenuButtonWrapperActive
    {
        background: $colorOrangeLighter;
    }

    &.sideMenuButtonWrapperActive
    {
        .sideMenuButtonTitle
        {
            font-weight: $fontWeightExtraBold;
            color:       $colorBlack;
        }

        .sideMenuButtonSubTitle
        {

        }
    }

    .sideMenuButtonTitle
    {
        font-family:    $fontSourceOpenSans;
        font-size:      14px;
        font-weight:    $fontWeightRegular;
        font-stretch:   normal;
        font-style:     normal;
        line-height:    1.43;
        letter-spacing: normal;
        color:          $colorGrayDarker;
        transition:     color 0.1s linear;
    }

    .sideMenuButtonSubTitle
    {
        font-family:    $fontSourceOpenSans;
        font-size:      10px;
        font-weight:    $fontWeightRegular;
        font-stretch:   normal;
        font-style:     normal;
        line-height:    1.11;
        letter-spacing: normal;
        color:          $colorGrayDarker;
        padding:        0;
        margin:         0;
    }
}
