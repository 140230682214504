//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.wrapper
{
    display:        flex;
    flex-direction: column;
    flex:           1;
    width:          100%;

    h3
    {
        font-size:      14px;
        font-weight:    $fontWeightExtraBold;
        text-transform: uppercase;
    }

    .fileEntry
    {
        padding-bottom:  5px;
        color:           $colorGrayDark;
        display:         flex;
        flex-direction:  row;
        gap:             10px;
        align-content:   center;
        justify-content: flex-start;
        align-items:     center;
    }

    .downloadCircleWrapper
    {
        width:  22px;
        height: 22px;
        color:  $colorBlack40;

        *
        {
            fill: $colorBlack40 !important;
        }
    }
}
