//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.collapsableHeadlineWrapper
{
    display:     flex;
    flex-flow:   row nowrap;
    align-items: baseline;

    .collapsableHeadlineCaret
    {
        margin-left:         5px;
        margin-right:        5px;
        width:               10px;
        height:              10px;
        background-image:    url('../../../../assets/icons/caret.svg');
        background-position: center;
        transition:          transform 0.3s linear;

        &.collapsableHeadlineCaretClosed
        {
            transform: rotate(180deg);
        }
    }
}
