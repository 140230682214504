//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.logo
{
    display:         flex;
    align-items:     center;
    justify-content: center;
    width:           auto;
    max-width:       350px;
    height:          auto;
    min-height:      100%;
    max-height:      100%;

    svg
    {
        width:      100%;
        min-width:  100%;
        max-width:  100%;
        height:     100%;
        min-height: 100%;
        max-height: 100%;
    }
}
