//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.wrapper
{
    background-color: $colorGrayLight;
    display:          flex;
    justify-content:  space-between;
    padding:          15px 0;
    width:            100%;
    gap:              10px;
    flex-direction:   column;

    .top
    {
        display:         flex;
        flex-direction:  row;
        justify-content: space-between;
        padding:         0 20px 15px 20px;
        margin-bottom:   10px;
        border-bottom:   2px solid $colorGray;
    }

    .bottom
    {
        padding:         0 20px 0 20px;
        display:         flex;
        flex-direction:  row;
        justify-content: space-between;
    }

    .left
    {
    }

    .right
    {
        justify-content: space-between;
    }

    .projectWrapper
    {
        display:        flex;
        flex-direction: column;

        .projectName
        {
            font-weight: $fontWeightBold;
        }

        .projectRuntimeVolumeWrapper
        {
            margin-top:     10px;
            display:        flex;
            flex-direction: row;
            gap:            10px;
            color:          $colorGrayDark;
            font-size:      14px;

            .projectRuntime
            {

            }

            .projectVolume
            {

            }
        }
    }

    .actions
    {
        flex:            1;
        display:         flex;
        justify-content: flex-end;

        .innerActions
        {
            flex-direction: row;
            gap:            10px;
            display:        flex;
        }
    }

    .match
    {
    }

    .status
    {
        flex: 1;

        .statusLabel
        {
            font-weight:  $fontWeightBold;
            margin-right: 5px;
        }

        .statusValue
        {
            font-weight: $fontWeightBold;

            &.colorBlack
            {
                color: $colorBlack;
            }

            &.colorOrange
            {
                color: $colorOrange;
            }

            &.colorRed
            {
                color: $colorRed;
            }
        }
    }
}
