//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import 'styles/all';
@import 'styles/zIndexes';

*
{
    margin:  0;
    padding: 0;
}

@media print
{
    html
    {
        margin: 0 50px;
    }
}

@page
{
    size:   landscape;
    margin: 0 -50px;
}

body
{
    margin:                  0;
    padding:                 0;
    min-width:               320px;
    overflow-y:              scroll;
    font-family:             $fontSourceOpenSans;
    -webkit-font-smoothing:  antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.fullScreen
{
    flex:      1;
    display:   flex;
    flex-flow: column;
    height:    100%;
    width:     100%;

    @media (min-width: $screen-md)
    {
        &:not(.smallFooter)
        {
            + footer
            {
                height: 50vh;
            }
        }
    }
}

.defaultContainer
{
    flex:             1;
    display:          flex;
    flex-flow:        column;
    width:            100vw;
    max-width:        100%;
    padding-bottom:   50px;
    margin-bottom:    -50px;
    background-color: $colorWhiteDark;

    .defaultInnerContainer,
    .defaultInnerTextContainer,
    .defaultColumnInnerContainer
    {
        width:       100%;
        display:     flex;
        flex-flow:   column;
        align-items: flex-start;
        margin:      0 auto;
        max-width:   $screen-lg;
        box-sizing:  border-box;
        padding:     0 10px 80px 10px;

        @media (min-width: $screen-md)
        {
            flex-flow: row nowrap;
        }

        @media (min-width: $screen-lg + 20px)
        {
            padding-left:  0;
            padding-right: 0;
        }

        &.defaultColumnInnerContainer
        {
            flex-flow: column;
        }

        .defaultContentContainer
        {
            flex:       1;
            width:      100%;
            min-width:  0;
            margin-top: 20px;

            @media (min-width: $screen-md)
            {
                margin-top:  0;
                margin-left: 29px;
            }

            &.defaultContentContainerNoSidebar
            {
                margin-left: 0;
            }
        }
    }

    .defaultInnerTextContainer
    {
        flex:      1;
        max-width: 750px;
        flex-flow: column;

        &.defaultInnerTextCenter
        {
            align-items:     center;
            justify-content: center;

            h1
            {
                margin-bottom: 0;
            }
        }

        h1
        {
            font-family:    $fontHKGrotesk;
            font-size:      55px;
            font-weight:    $fontWeightBold;
            font-stretch:   normal;
            font-style:     normal;
            line-height:    1.27;
            letter-spacing: normal;
            color:          $colorBlackLight;
            margin-bottom:  140px;
        }

        p
        {
            font-family:    $fontSourceOpenSans;
            font-size:      18px;
            font-weight:    $fontWeightRegular;
            font-stretch:   normal;
            font-style:     normal;
            line-height:    1.67;
            letter-spacing: normal;
            color:          $colorGrayDarker;
        }
    }
}

.textSmall
{
    font-family:    $fontSourceOpenSans;
    font-size:      12px;
    font-weight:    $fontWeightRegular;
    font-stretch:   normal;
    font-style:     normal;
    line-height:    1.67;
    letter-spacing: normal;
    color:          $colorGrayDarker;
}

.textCenter
{
    text-align: center;
}

.link
{
    font-family:     $fontSourceOpenSans;
    font-size:       12px;
    font-weight:     $fontWeightExtraBold;
    font-stretch:    normal;
    font-style:      normal;
    line-height:     1.67;
    letter-spacing:  normal;
    color:           $colorOrange;
    text-decoration: none;
}

code
{
    font-family: $fontSourceCourierNew;
}

.appContent
{
    display:    flex;
    flex-flow:  column;
    min-height: 100vh;
}

:global
{
    .ReactCollapse--collapse
    {
        transition: height 0.5s;
    }

    [data-tippy-root]
    {
        z-index:       $zIndexToolTip !important;
        border-radius: 100px;

        .tippy-content
        {
            > div
            {
                > p
                {
                    p
                    {
                        font-family:    $fontSourceOpenSans;
                        font-size:      14px;
                        font-weight:    $fontWeightExtraBold;
                        font-stretch:   normal;
                        font-style:     normal;
                        line-height:    1.43;
                        letter-spacing: 0.5px;
                        text-align:     center;
                        text-transform: uppercase;
                    }

                    span
                    {
                        display:        inline-block;
                        opacity:        0.85;
                        font-family:    $fontSourceOpenSans;
                        font-size:      14px;
                        font-weight:    $fontWeightRegular;
                        font-stretch:   normal;
                        font-style:     normal;
                        line-height:    1.43;
                        letter-spacing: normal;
                        text-align:     center;
                        margin-top:     6px;
                    }
                }
            }
        }
    }
}

button
{
    text-align: left;
}

svg
{
    min-width:  100%;
    min-height: 100%;
}

input
{
    &:focus
    {
        outline: none;
        border:  1px solid $colorOrange;
    }
}
