//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.startPageBoxFooter
{
    margin-top: 23px;

    &.alignCenter
    {
        text-align: center;
    }

    &.alignRight
    {
        text-align: right;
    }

    &.alignLeft
    {
        text-align: left;
    }
}
