//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.factSheetWrapper
{
    width:            100%;
    background-color: $colorGrayLight;
    margin-bottom:    25px;

    .factSheetWrapperHeader
    {
        display:         flex;
        flex-flow:       row nowrap;
        align-items:     flex-start;
        justify-content: space-between;
        padding:         24px 20px;
        text-transform:  uppercase;

        .factSheetWrapperHeadline
        {
            font-size:   14px;
            font-weight: $fontWeightExtraBold;
            color:       $colorBlackLight;
        }

        .factSheetWrapperActions
        {
            display:         flex;
            flex-flow:       column;
            align-items:     flex-end;
            justify-content: space-between;
        }
    }

    .factSheetWrapperContent
    {
        width: 100%;
    }
}
