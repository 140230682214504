//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

$screen-xs:                      480px;
$screen-xs-min:                  $screen-xs;
$screen-phone:                   $screen-xs-min;

$screen-sm:                      768px;
$screen-sm-min:                  $screen-sm;
$screen-tablet:                  $screen-sm-min;

$screen-md:                      992px;
$screen-md-min:                  $screen-md;
$screen-desktop:                 $screen-md-min;

$screen-lg:                      1200px;
$screen-lg-min:                  $screen-lg;
$screen-lg-desktop:              $screen-lg-min;

$screen-xs-max:                  ($screen-sm-min - 1);
$screen-sm-max:                  ($screen-md-min - 1);
$screen-md-max:                  ($screen-lg-min - 1);

$currentRoleContainerBreakpoint: 600px;
