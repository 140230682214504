//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.requestMessageTagWrapper
{
    > h3
    {
        margin-top:    20px;
        margin-bottom: 15px;
    }

    .requestMessageTagRow
    {
        display:       flex;
        flex-flow:     row;
        align-content: center;
        margin-top:    15px;

        > div
        {
            align-self: center;
            color:      $colorGrayHeather;
            width:      50%;

            &:last-child
            {
                margin-left: 20px;
                width:       auto;
            }
        }
    }
}
