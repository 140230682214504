//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

button
{
    &.message
    {
        cursor: pointer;
    }
}

.message
{
    padding: 6px 11px;

    &:nth-of-type(odd)
    {
        background-color: $colorGrayLight;
    }

    .messageRequestPreview
    {
        display:        flex;
        flex-direction: row;
        align-items:    center;
        gap:            30px;
        min-width:      170px;

        .messageRequestWrapper
        {
            display:         flex;
            flex-flow:       row;
            align-items:     center;
            justify-content: space-between;
            width:           100%;

            p
            {
                font-size:     12px;
                line-height:   1.25;
                color:         $colorGrayDark;
                padding-right: 20px;
                white-space:   nowrap;
                text-overflow: ellipsis;
                overflow:      hidden;
                margin-left:   10px;
                margin-right:  10px;
                height:        40px;

                @media (min-width: $screen-md)
                {
                    margin-left:  25px;
                    margin-right: 0;
                }
            }

            span
            {
                margin-left: auto;
            }
        }

        .messageRequestButtons
        {
            display:     flex;
            flex-flow:   row nowrap;
            align-items: center;
            gap:         20px;
            margin:      auto 20px auto auto;

            > button
            {
                width: 90px;
            }
        }
    }

    .messageRequestDivider
    {
        width:            100%;
        height:           1px;
        background-color: $colorGray;
        margin-bottom:    17px;
        margin-top:       30px;
    }
}
