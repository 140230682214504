//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.imageUploadContainer
{
    display:   flex;
    flex-flow: row nowrap;

    .imageUploadImage
    {
        position:            relative;
        width:               75px;
        height:              75px;
        border:              1px solid $colorGray;
        padding:             5px;
        box-sizing:          border-box;
        border-radius:       5px;
        background-size:     cover;
        background-color:    $colorWhite;
        background-clip:     content-box;
        background-origin:   content-box;
        background-repeat:   no-repeat;
        background-position: center;
        margin-right:        10px;

        &:hover
        {
            .imageUploadDeleteButton
            {
                opacity: 1;
            }
        }

        &.imageUploadFieldLarge
        {
            width:  120px;
            height: 120px;
        }

        .imageUploadDeleteButton
        {
            position:            absolute;
            top:                 5px;
            right:               5px;
            display:             block;
            cursor:              pointer;
            border:              none;
            width:               20px;
            height:              20px;
            overflow:            hidden;
            text-indent:         -9999px;
            opacity:             0;
            background-size:     70%;
            background-color:    $colorBlackPitch50;
            background-image:    url('/assets/icons/cross.svg');
            background-repeat:   no-repeat;
            background-position: center;
            transition:          opacity 0.1s linear;
        }
    }

    .imageUploadField
    {
        position:            relative;
        width:               75px;
        height:              75px;
        border:              1px solid $colorGray;
        box-sizing:          border-box;
        border-radius:       5px;
        overflow:            hidden;
        background-color:    $colorWhite;
        background-image:    url('/assets/icons/camera.svg');
        background-repeat:   no-repeat;
        background-position: center top 12px;
        margin-right:        10px;

        &.imageUploadFieldLarge
        {
            width:               120px;
            height:              120px;
            background-position: center top 30px;
        }

        label
        {
            position:        absolute;
            top:             0;
            left:            0;
            width:           100%;
            height:          100%;
            display:         flex;
            align-items:     center;
            justify-content: center;

            input
            {
                position:  absolute;
                z-index:   0;
                top:       0;
                left:      0;
                width:     300%;
                height:    100%;
                cursor:    pointer;
                opacity:   0;
                transform: translateX(-66%);
            }

            span
            {
                font-family:    $fontSourceOpenSans;
                font-size:      12px;
                font-weight:    $fontWeightRegular;
                font-stretch:   normal;
                font-style:     normal;
                line-height:    1.25;
                letter-spacing: normal;
                color:          $colorGrayDark;
                margin-top:     34px;
            }
        }
    }
}
