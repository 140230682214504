//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.dateInputWrapper
{
    $borderRadius: 5px;

    box-sizing:    border-box;
    width:         100%;
    background:    $colorWhite;
    object-fit:    contain;
    border-radius: $borderRadius;
    position:      relative;
    border:        1px solid $colorGray;

    &.disabled
    {
        opacity:        0.5;
        pointer-events: none;
    }
}

.datePicker
{
    width: 100%;
}

.datePickerPopper
{
    top:   5px !important;
    width: calc(100% - 2px);
}

.dateInput
{
    $borderRadius: 5px;

    border:        none;
    width:         100%;
    padding:       12px 15px;
    border-radius: $borderRadius;

    &:focus-visible
    {
        outline-offset: 0;
        outline:        none;
    }
}

.iconWrapper
{
    pointer-events: none;
    width:          22px;
    height:         22px;
    position:       absolute;
    top:            8px;
    right:          6px;

    svg
    {
        *
        {
            @include svgColorFillImportant($colorGrayDarkBlue);
        }
    }
}

:global
{
    .react-datepicker
    {
        width:  100%;
        border: 1px solid $colorOrange !important;
    }

    .react-datepicker__month-container
    {
        width: 100%;
    }

    .react-datepicker__header
    {
        border-bottom:    1px solid $colorGray !important;
        background-color: $colorWhite !important;
    }

    .react-datepicker__day--selected
    {
        color:            $colorOrange !important;
        background-color: transparent !important;

        &::after
        {
            content:          '';
            display:          block;
            width:            5px;
            height:           5px;
            border-radius:    3px;
            margin:           0 auto;
            background-color: $colorOrange;
        }
    }
}
