//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.addressLabelWrapper
{
    display:     flex;
    align-items: center;

    .countryFlagWrapper
    {
        display:         flex;
        align-items:     center;
        justify-content: center;
        width:           24px;
        margin:          0 25px 0 0;
        height:          100%;

        > span
        {
            display:   inline-block;
            width:     20px !important;
            font-size: 16px !important;
        }
    }

    .labelWrapper
    {
        > *
        {
            display: block;
        }

        strong
        {
            font-family:    $fontSourceOpenSans;
            font-size:      14px;
            font-weight:    $fontWeightExtraBold;
            font-stretch:   normal;
            font-style:     normal;
            line-height:    1.43;
            letter-spacing: normal;
            color:          $colorBlackLight;
            margin:         0 0 2px 0;
        }

        span
        {
            font-family:    $fontSourceOpenSans;
            font-size:      12px;
            font-weight:    $fontWeightRegular;
            font-stretch:   normal;
            font-style:     normal;
            line-height:    1.25;
            letter-spacing: normal;
            color:          $colorGrayDarkBlue;
        }
    }
}
