//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../styles/all';

.projectsContainer
{
    display:          flex;
    flex-flow:        row nowrap;
    background-color: $colorWhite;

    .projectsContent
    {
        width:   100%;
        padding: 23px 25px;
    }
}

.ownProjectsHeadline
{
    display:     flex;
    flex-flow:   row nowrap;
    align-items: center;
    gap:         10px;

    path
    {
        fill: $colorGrayDark;
    }
}

.badgeCountContainer
{
    margin-top:  2px;
    margin-left: 10px;
}
