//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.iconButton
{
    width:           20px;
    height:          20px;
    min-width:       20px;
    min-height:      20px;
    cursor:          pointer;
    display:         flex;
    justify-content: center;
}

.iconButtonPlain
{

}

.iconButtonGrayBorder
{
    $size:         20px;

    width:         $size;
    height:        $size;
    padding:       5px;
    border-radius: 5px;
    border:        1px solid $colorGray;
    transition:    border-color 0.1s linear,
                   background-color 0.1s linear;

    &:hover
    {
        border-color:     $colorOrange;
        background-color: $colorOrange;

        svg
        {
            *
            {
                @include svgColorFill($colorWhite);
            }
        }
    }

    svg
    {
        height: $size;
        width:  $size;

        *
        {
            @include svgColorFill($colorGrayHeather);

            transition: fill 0.1s linear;
        }
    }
}

.iconButtonGrayRound
{
    $size:           20px;

    width:           $size;
    height:          $size;
    border-radius:   $size * 0.5;
    background:      $colorGrayDarkBlue;
    display:         flex;
    justify-content: center;
    align-items:     center;
    transition:      transform 0.1s linear,
                     background-color 0.1s linear;

    &:hover
    {
        background: $colorGrayDarker;
    }

    svg
    {
        $size:  14px;

        height: $size;
        width:  $size;
    }
}

.iconButtonBlackRectangular
{
    $size:           20px;

    width:           $size;
    height:          $size;
    background:      $colorBlackLight;
    color:           $colorWhiteDark;
    display:         flex;
    justify-content: center;
    align-items:     center;
    transition:      transform 0.1s linear,
                     background-color 0.1s linear;

    &:hover
    {
        background: $colorGrayDarker;
    }

    svg
    {
        $size:  14px;

        height: $size;
        width:  $size;
    }
}
