//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.startPageWithBoxContent
{
    flex:            1;
    z-index:         1;
    top:             0;
    left:            50%;
    width:           830px;
    margin:          0 auto;
    max-width:       100%;
    height:          90%;
    display:         flex;
    flex-flow:       column;
    align-items:     center;
    justify-content: space-between;
    box-sizing:      border-box;

    @media (min-width: $screen-md)
    {
        position:  absolute;
        flex-flow: row wrap;
        transform: translateX(-50%);
    }

    .startPageWithBoxIconHeader
    {
        margin: 61px auto 20px;

        svg
        {
            path
            {
                @include svgColorFill($colorOrange);
            }
        }
    }

    .startPageWithBoxHeadlineSubtext
    {
        width:          100%;
        text-align:     center;
        opacity:        1;
        color:          $colorGrayDarker;
        font-size:      14px;
        font-weight:    400;
        font-style:     normal;
        letter-spacing: 0;
        line-height:    20px;
        margin:         0 auto 55px;
    }

    h1
    {
        display:        flex;
        flex-flow:      column;
        font-family:    $fontSourceOpenSans;
        font-size:      30px;
        font-weight:    $fontWeightBold;
        font-stretch:   normal;
        font-style:     normal;
        line-height:    normal;
        letter-spacing: normal;
        text-align:     left;
        color:          $colorWhite;
        margin-top:     50px;

        @media (min-width: $screen-md)
        {
            margin-bottom: 180px;
        }

        span
        {
            display:       inline-block;
            margin-bottom: 25px;
        }
    }
}
