//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../styles/all';

.companyFactSheetContainer
{
    display:          flex;
    flex-flow:        row nowrap;
    background-color: $colorWhite;

    .companyFactSheetContent
    {
        width:      100%;
        padding:    13px 15px;
        box-sizing: border-box;

        @media (min-width: $screen-md)
        {
            padding: 23px 25px;
        }

        .companyFactSheetRow
        {
            display:         flex;
            flex-flow:       column;
            gap:             24px;
            justify-content: space-between;

            @media (min-width: $screen-md)
            {
                flex-flow: row;
            }
        }
    }
}
