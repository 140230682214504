//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

$colorGrayDarkBlue:     #959ead;
$colorGrayHeather:      #bcbfc2;
$colorGrayDark:         #939699;
$colorGrayDark1:        #656b70;
$colorFadingGray:       #68879c;
$colorGrayBlue:         #67879c;
$colorGrayDarker:       #4b5157;
$colorBlackLight:       #262d33;
$colorMineShaft:        #333333;
$colorBlack:            #11142d;
$colorBlack30:          #11142d30;
$colorBlack40:          #11142d40;
$colorBlack60:          #11142d60;
$colorBlackPitch:       #000000;
$colorBlackPitch50:     rgba(0, 0, 0, 0.5);
$colorBlackPitch25:     rgba(0, 0, 0, 0.25);
$colorWhite:            #ffffff;
$colorWhite40:          rgba(255, 255, 255, 0.4);
$colorWhite50:          rgba(255, 255, 255, 0.5);
$colorWhite80:          rgba(255, 255, 255, 0.8);
$colorWhiteDark:        #edeff0;
$colorDodgerBlue:       #2094ff;
$colorGray:             #d9dadb;
$colorGrayLight:        #f5f5f5;
$colorMercury:          #e6e6e6;
$colorOrangeLighter:    #feefde;
$colorOrangeLighter1:   #fde4c7;
$colorOrangeLight:      #fbc98e;
$colorOrange:           #f7931e;
$colorOrange80:         rgba(247, 147, 30, 0.8);
$colorOrangeDark:       #f47612;
$colorPurple:           purple;
$colorRedRoseBud:       #ffa3ab;
$colorRedWaxFlower:     #ffbdad;
$colorRed:              #cd4642;
$colorGreenEmerald:     #3dc47e;
$colorRedCinnabar:      #de373a;
$colorRedFireEngineRed: #af1416;
$colorBlueCalypso:      #356d8f;
$colorBlueCalypso80:    rgba(53, 109, 143, 0.7);
$colorBlueNileBlue:     #183b56;
$colorBlueNileBlue80:   rgba(24, 59, 86, 0.7);
$colorBlue:             #1d4461;
$colorTransparent:      transparent;

// Alert Colors
$colorGreenDark:        #0f5132;
$colorGreenLight:       #badbcc;
$colorGreenVeryLight:   #d1e7dd;
$colorRedDark:          #842029;
$colorRedLight:         #f5c2c7;
$colorRedVeryLight:     #f8d7da;
