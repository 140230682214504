//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.noJoinRequestsRow
{
    height:          80px;
    display:         flex;
    align-items:     center;
    justify-content: center;
    text-align:      center;
    color:           $colorGrayDark;
}

.tableButtonColumn
{
    width: 35% !important;

    .tableButtonColumnChildren
    {
        display:        flex;
        flex-direction: row;
        align-items:    center;
    }
}
