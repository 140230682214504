//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.favoriteCompanyBox
{
    display: inline-block;
    padding: 20px 0 !important;

    > *
    {
        padding: 0 25px;
    }

    h2
    {
        max-width: 100%;
    }

    .favoriteCompanyBoxHeader
    {
        display:         flex;
        flex-flow:       row nowrap;
        justify-content: space-between;
    }

    .spacer
    {
        display:          block;
        width:            100%;
        padding:          0 !important;
        height:           1px;
        margin:           16px 0;
        background-color: $colorGray;
    }
}
