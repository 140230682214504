//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.wrapper
{
    & + .wrapper
    {
        margin-top: 20px;
    }

    .header
    {
    }

    .list
    {
        margin-top: 20px;
    }
}

.badgeCountContainer
{
    margin-top:  3px;
    margin-left: 7px;
}
