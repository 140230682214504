//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.tableBodyRow
{
    width:            300%;
    max-width:        100%;
    min-width:        100%;
    box-sizing:       border-box;
    display:          flex;
    flex-flow:        column;
    gap:              5px;
    background-color: $colorWhite;

    @media (min-width: $screen-md)
    {
        display: table-row;
        height:  $tableBodyRowHeight;
    }

    &:nth-child(odd)
    {
        background-color: $colorGrayLight;
    }

    &.tableBodyRowClickable
    {
        cursor: pointer;

        // If there is only one blurred, make more space for the CTA
        + .tableBodyRow:not(.tableBodyRowClickable):last-child
        {
            height: 140px;
        }
    }

    @media (max-width: $screen-sm-max)
    {
        > td:nth-child(3)
        {
            padding: 0 0 0 15px;
        }
    }
}
