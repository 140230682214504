//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.tagSearchResultWrapper
{
    height:        41px;
    position:      relative;
    padding:       0 10px 0 56px;
    line-height:   41px;
    font-family:   $fontSourceOpenSans;
    font-size:     14px;
    white-space:   nowrap;
    overflow:      hidden;
    text-overflow: ellipsis;
    text-align:    left;
    direction:     rtl;
    cursor:        pointer;

    &:hover
    {
        background-color: $colorOrangeLighter1;
    }

    .searchIconWrapper
    {
        pointer-events: none;
        width:          16px;
        height:         16px;
        position:       absolute;
        top:            16px;
        left:           20px;

        svg
        {
            *
            {
                @include svgColorFill($colorGrayDarkBlue);
            }
        }
    }
}
