//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.iconTextButtonWrapper
{
    display:     flex;
    flex-flow:   row nowrap;
    align-items: center;

    &:hover,
    &.active
    {
        > span
        {
            color: $colorWhite;
        }
    }

    &.disabled
    {
        pointer-events: none;

        > span
        {
            color: $colorGray;
        }
    }

    &.hideMobile
    {
        @media (max-width: $screen-sm-max)
        {
            display: none !important;
        }
    }

    &.noText
    {
        padding:   5px 5px !important;
        min-width: 30px;
    }

    > span
    {
        font-family:    $fontSourceOpenSans;
        font-size:      14px;
        font-weight:    $fontWeightExtraBold;
        font-stretch:   normal;
        font-style:     normal;
        line-height:    1.43;
        letter-spacing: normal;
        color:          $colorBlackLight;
        transition:     color 0.1s linear;
    }

    &.iconTextButton
    {
        width:           auto;
        cursor:          pointer;
        display:         flex;
        white-space:     nowrap;
        justify-content: center;

        &.iconTextButtonRight
        {
            flex-flow: row nowrap;

            > span:not(:first-child)
            {
                margin-right: 5px;
            }
        }

        &.iconTextButtonLeft
        {
            flex-flow: row-reverse nowrap;

            > span:not(:last-child)
            {
                margin-left: 5px;
            }
        }
    }

    &.iconTextButtonPlain
    {

    }

    &.iconTextButtonGrayBorder
    {
        padding:       5px 10px;
        border-radius: 5px;
        border:        1px solid $colorGray;
        transition:    border-color 0.1s linear,
                       background-color 0.1s linear;

        &:hover,
        &.active
        {
            border-color: $colorGrayDark1;

            span
            {
                color: $colorBlack;
            }

            svg
            {
                *
                {
                    @include svgColorFill($colorWhite);
                }
            }
        }

        &:hover.disabled
        {
            pointer-events:   none;
            border-color:     $colorGray;
            background-color: unset;

            svg
            {
                *
                {
                    @include svgColorFill($colorGrayHeather);
                }
            }
        }

        svg
        {
            $size:  14px;

            height: $size;
            width:  $size;

            :global
            {
                .color
                {
                    @include svgColorFill($colorGrayHeather);

                    transition: fill 0.1s linear;
                }
            }
        }
    }

    &.iconTextButtonGrayBorderGrayHover
    {
        padding:       5px 10px;
        border-radius: 5px;
        border:        1px solid $colorGray;
        color:         $colorBlackLight;
        transition:    border-color 0.1s linear,
                       background-color 0.1s linear;

        &:hover,
        &.active
        {
            border-color:     $colorGrayDark1;
            color:            $colorBlackLight;
            background-color: inherit;

            span
            {
                color: $colorBlackLight;
            }

            svg
            {
                *
                {
                    @include svgColorFill($colorGrayDark1);
                }
            }
        }

        svg
        {
            $size:  14px;

            height: $size;
            width:  $size;

            *
            {
                @include svgColorFill($colorGrayHeather);

                transition: fill 0.1s linear;
            }
        }
    }

    &.iconTextButtonGrayRound
    {
        padding:       5px 10px;
        border-radius: 15px;
        border:        1px solid $colorGray;
        transition:    border-color 0.1s linear,
                       background-color 0.1s linear;

        &:hover,
        &.active
        {
            border-color:     $colorOrange;
            background-color: $colorOrange;

            svg
            {
                *
                {
                    @include svgColorFill($colorWhite);
                }
            }
        }

        svg
        {
            $size:  14px;

            height: $size;
            width:  $size;

            *
            {
                @include svgColorFill($colorGrayHeather);

                transition: fill 0.1s linear;
            }
        }
    }
}
