//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.formHeadline
{
    display:        flex;
    flex-flow:      row nowrap;
    align-items:    center;
    text-transform: uppercase;

    > div
    {
        margin-left: 11px;
    }

    svg
    {
        path
        {
            @include svgColorFill($colorGrayHeather);
        }
    }
}
