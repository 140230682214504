//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.mapContainer
{
    position: relative;

    .mapLoading
    {
        position:            absolute;
        top:                 0;
        left:                0;
        width:               100%;
        height:              100%;
        background-image:    url('/assets/images/map.jpg');
        background-size:     cover;
        background-position: center;
    }
}
