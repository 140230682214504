//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.joinCompanyHeader
{
    display:         flex;
    flex-direction:  column;
    align-items:     center;
    justify-content: space-between;
    padding:         18px 50px;
    margin-bottom:   30px;
    background:      linear-gradient(263deg, $colorBlueCalypso 100%, $colorBlueNileBlue);

    @media (min-width: $screen-sm)
    {
        flex-direction: row;
    }

    .joinNowText
    {
        font-family: $fontHKGrotesk;
        font-size:   30px;
        font-weight: bold;
        line-height: 0.75;
        color:       $colorOrange;

        > :nth-child(2)
        {
            color: $colorWhite;
        }

        > :not(:last-child)
        {
            margin-right: 8px;
        }
    }

    .logo
    {
        height:    30px;
        flex:      0 0 70%;
        max-width: 160px;

        @media (max-width: $screen-xs-max)
        {
            margin: 20px 0;
        }
    }

    .joinButton,
    .joinNowText
    {
        flex: 0 0 15%;
    }

    .joinButton
    {
        display:         flex;
        flex-direction:  row;
        justify-content: flex-end;

        svg
        {
            path
            {
                @include svgColorFillImportant($colorWhite);
            }
        }

        button
        {
            &:hover
            {
                border-color: $colorWhite;
            }

            span
            {
                color: $colorWhite !important;
            }
        }
    }
}
