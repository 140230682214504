//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.badgeCount
{
    position:         absolute;
    display:          flex;
    align-items:      center;
    justify-content:  center;
    width:            20px;
    height:           20px;
    border-radius:    10px;
    font-family:      $fontSourceOpenSans;
    font-size:        12px;
    font-weight:      $fontWeightExtraBold;
    font-stretch:     normal;
    font-style:       normal;
    line-height:      1.25;
    letter-spacing:   normal;
    text-align:       center;
    color:            $colorWhite;
    user-select:      none;
    background-color: $colorOrange;
}
