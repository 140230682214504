//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.tableHeaderRow
{
    height:        55px;
    border-bottom: 1px solid $colorGray;

    @media (max-width: $screen-sm-max)
    {
        > th:nth-child(3)
        {
            padding: 0;
        }
    }
}
