//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.profileCompletionBatch
{
    position:              relative;
    width:                 100%;
    padding:               16px 24px;
    background:            linear-gradient(259deg, $colorBlueCalypso, $colorBlueNileBlue);
    margin-bottom:         30px;
    display:               grid;
    grid-template-columns: 1fr;
    box-sizing:            border-box;
    gap:                   20px;
    border-radius:         2px;

    @media (min-width: $screen-md)
    {
        grid-template-columns: 2fr 3fr;
    }

    .hideButton
    {
        $closeButtonSize: 16px;

        position:         absolute;
        width:            $closeButtonSize;
        height:           $closeButtonSize;
        top:              10px;
        right:            10px;
        cursor:           pointer;

        svg
        {
            path
            {
                @include svgColorFillImportant($colorFadingGray);
            }
        }
    }

    .completionTextAndRing
    {
        width:   100%;
        display: flex;
        gap:     20px;

        .completionRing
        {
            $ringSize:  80px;

            width:      $ringSize;
            height:     $ringSize;
            min-width:  auto;
            min-height: auto;

            .completionRingInner
            {
                font-size: 16px;
                color:     $colorGrayBlue;
            }
        }

        .completionText
        {
            color: $colorWhite;

            h4
            {
                font-size:     21px;
                font-weight:   $fontWeightBold;
                margin-bottom: 5px;
            }

            p
            {
                font-size: 14px;
            }
        }
    }

    .completionSteps
    {
        width:                 100%;
        display:               grid;
        grid-template-rows: repeat(5, 1fr);
        grid-template-columns: 1fr 1fr;
        grid-auto-flow:        column;
        gap:                   10px;

        .completionStep
        {
            display:     flex;
            width:       100%;
            gap:         10px;
            color:       $colorWhite;
            align-items: center;
            opacity:     0.85;
            font-size:   14px;

            &.fullFilled
            {
                font-weight:    $fontWeightBold;
                opacity:        1;
                text-transform: uppercase;
            }

            span
            {
                $iconSize: 24px;

                width:     24px;
                height:    24px;
            }
        }
    }
}
