//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.icon
{
    position:        relative;
    width:           100%;
    height:          auto;
    max-width:       100%;
    max-height:      100%;
    display:         flex;
    align-items:     center;
    justify-content: center;
    background-size: contain;

    svg
    {
        width:      auto;
        height:     auto;
        max-width:  100%;
        max-height: 100%;

        *
        {
            transition: fill 0.1s linear,
                        stroke 0.1s linear;
        }

        :global
        {
            .color
            {
                fill: $colorWhite;
            }

            .background
            {
                fill: $colorGrayHeather;
            }
        }
    }
}
