//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.breadCrumbBarContainer
{
    flex:       0;
    min-height: auto;

    .breadCrumbBar
    {
        display:          flex !important;
        flex-flow:        row nowrap !important;
        align-items:      center !important;
        width:            100% !important;
        height:           75px;
        padding:          0 10px !important;
        background-color: $colorWhiteDark;

        @media (min-width: $screen-lg + 20px)
        {
            padding-left:  0 !important;
            padding-right: 0 !important;
        }

        ul
        {
            display:     flex;
            flex-flow:   row wrap;
            list-style:  none;
            align-items: center;

            li
            {
                &:last-child
                {
                    a::after,
                    span::after
                    {
                        content: none;
                    }
                }

                a,
                span
                {
                    color:           $colorBlackLight;
                    font-family:     $fontHKGrotesk;
                    font-size:       14px;
                    font-weight:     $fontWeightBold;
                    font-stretch:    normal;
                    font-style:      normal;
                    line-height:     1.43;
                    letter-spacing:  normal;
                    text-decoration: none;

                    &::after
                    {
                        display:          inline-block;
                        content:          '';
                        width:            10px;
                        height:           10px;
                        margin:           0 10px;
                        background-image: url('../../../../assets/icons/caret-right.svg');
                    }
                }

                .breadCrumbButton
                {
                    display:     flex;
                    align-items: center;

                    a,
                    span
                    {
                        color:       $colorBlack;
                        font-weight: $fontWeightExtraBold;
                        font-family: $fontSourceOpenSans;

                        &::after
                        {
                            margin:           0;
                            padding:          0;
                            background-image: none;
                        }
                    }
                }
            }
        }
    }
}
