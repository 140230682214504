//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.tableContainer
{
    width:     100%;
    position:  relative;
    max-width: 100%;

    .tableFilter
    {
        top:        14px;
        left:       25px;
        position:   absolute;
        max-width:  25px;
        max-height: 25px;

        @media (min-width: 1220px)
        {
            left:  inherit;
            right: 25px;
        }

        svg
        {
            width:  70% !important;
            height: 70% !important;

            *
            {
                @include svgColorFill($colorBlackLight);
            }
        }
    }

    .tableWithFilter
    {
        width:          100%;
        overflow-x:     hidden;
        border-radius:  2px;
        border-spacing: 0;

        @media (min-width: $screen-md)
        {
            min-width: fit-content;
        }

        thead
        {
            > tr:first-child
            {
                @media (max-width: $screen-sm-max)
                {
                    > th:nth-child(n+2)
                    {
                        display: none;
                    }
                }

                > th:last-child
                {
                    padding-right: 65px;
                }
            }
        }
    }
}
