//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../styles/all';

.projectsContainer
{
    display:          flex;
    flex-flow:        row nowrap;
    background-color: $colorWhite;

    .projectsContent
    {
        width:   100%;
        padding: 23px 25px;
    }
}

.ownProjectsHeadline
{
    display:     flex;
    flex-flow:   row nowrap;
    align-items: center;
    gap:         10px;

    path
    {
        fill: $colorGrayDark;
    }
}

.suggestedProjectsBadgeCountContainer
{
    margin-top:  2px;
    margin-left: 10px;
}

.sidebar
{
    border-left:    1px solid $colorGray;
    width:          230px;
    padding-left:   20px;
    padding-top:    20px;
    padding-bottom: 20px;
    margin-top:     -20px;
    margin-bottom:  -20px;
}

.requestingCompany
{
}

.attachments
{
    display:               grid;
    grid-template-columns: auto 1fr;
    row-gap:               5px;
    margin-top:            10px;
}

.attachment
{
    display:     flex;
    grid-column: 1;
    color:       $colorGrayDark;
}

.link
{
    grid-column: 2;
}

.iconWrapper
{
    width:  20px;
    height: 20px;

    span
    {
        svg
        {
            :global
            {
                .color
                {
                    @include svgColorFill($colorGrayHeather);
                }
            }
        }
    }
}
