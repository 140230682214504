//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.magicBannerRequired
{
    position:        relative;
    display:         flex;
    flex-flow:       column;
    align-items:     center;
    justify-content: center;
    gap:             15px;
    width:           100%;
    height:          100%;
    border:          1px solid $colorOrange;
}
