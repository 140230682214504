//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../styles/all';

.logoSmall
{
    width: 200px;
}

.wrapper
{
    display:          flex;
    flex-flow:        column;
    align-items:      flex-start;
    padding:          25px 35px;
    border-radius:    5px;
    box-shadow:       0 2px 9px 0 $colorBlackPitch50;
    background-color: $colorWhite;
    box-sizing:       border-box;
}

.spacing
{
    > *:not(:first-child)
    {
        margin-top: 10px;
    }
}

.buttonWrapper
{
    display:          flex;
    flex-flow:        row nowrap;
    justify-content:  space-between;
    padding:          0 20px;
    background-color: $colorWhite;

    &.buttonWrapperBackground
    {
        background-color: #00eeff;
    }

    > *
    {
        margin: 20px 10px;
    }
}

.loginButtonWrapper
{
    display:         flex;
    flex-flow:       row nowrap;
    justify-content: space-between;
    margin-top:      29px;
}

.lostPasswordTextWrapper
{
    display:         flex;
    flex-flow:       row nowrap;
    justify-content: center;
    margin-top:      23px;
}
