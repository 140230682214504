//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.addNewMachineHeaderContainer
{
    h2
    {
        margin-bottom: 15px;
    }
}

.addNewMachineBodyContainer
{
    .addNewMachineBodyBaseContainer
    {
        display:   flex;
        flex-flow: column;

        @media (min-width: $screen-md)
        {
            flex-flow: row nowrap;
        }

        .addNewMachineBodyImageContainer
        {
            margin-bottom: 20px;

            @media (min-width: $screen-md)
            {
                margin-right:  30px;
                margin-bottom: 0;
            }
        }

        .addNewMachineBodyTextContainer
        {
            flex:      1;
            display:   flex;
            flex-flow: column;

            .addNewMachineBodyDescriptionContainer
            {
                margin-top: 15px;

                p
                {
                    color:       $colorGrayDark;
                    font-size:   13px;
                    white-space: pre-line;
                }
            }
        }
    }

    .addNewMachineBodyContainer
    {
        margin-top: 55px;

        h3
        {
            margin-bottom: 15px;
        }
    }
}
