//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.tag
{
    position: relative;

    .tagContent
    {
        margin-top:       5px;
        margin-right:     5px;
        padding:          6px 10px;
        border-radius:    10px;
        font-family:      $fontSourceOpenSans;
        font-size:        12px;
        font-weight:      $fontWeightExtraBold;
        font-stretch:     normal;
        font-style:       normal;
        line-height:      2.22;
        letter-spacing:   normal;
        text-align:       center;
        color:            $colorBlackLight;
        background-color: $colorOrangeLighter;
        cursor:           pointer;
        transition:       background-color 0.1s linear;

        &:last-child
        {
            margin-right: 0;
        }

        &:hover
        {
            background-color: $colorOrangeLight;
        }
    }
}
