//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../styles/all';

.membershipContainer
{
    flex:             1;
    padding:          0 10px 100px 10px;
    background-color: $colorWhiteDark;

    @media (min-width: $screen-md)
    {
        padding: 0 0 70px 0;
    }

    .form
    {
        width:     660px;
        max-width: 100%;
        margin:    0 auto 50px auto;

        .inputs
        {
            display:   flex;
            flex-flow: column;
            gap:       15px;

            h2
            {
                margin-bottom: 50px;
            }

            .row
            {
                display:   flex;
                flex-flow: row wrap;
                gap:       20px;

                &.rowHalf
                {
                    @media (min-width: $screen-md)
                    {
                        max-width: calc(50% - 10px);
                    }
                }

                > *
                {
                    flex:      1;
                    min-width: 200px;
                }

                .companyLanguage
                {
                    display:     flex;
                    flex-flow:   row nowrap;
                    align-items: center;

                    > span
                    {
                        margin-right: 12px;
                    }
                }
            }

            + div
            {
                margin-top:    50px;
                margin-bottom: 30px;
            }

            input[disabled]
            {
                background-color: $colorWhite;
            }
        }

        hr
        {
            border-top: 1px solid $colorGray;
            margin-top: 50px;
        }

        > button
        {
            margin-top: 20px;
        }
    }
}
