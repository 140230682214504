//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.roundDropDownWrapper
{
    .belowText
    {
        color:         $colorGrayDark;
        font-size:     12px;
        padding:       5px 10px;
        border:        1px solid $colorGrayLight;
        border-radius: 0 0 10px 10px;

        strong
        {
            color: $colorBlack;
        }
    }
}
