//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.factSheetFactsWrapper
{
    margin:          auto;
    padding-left:    20px;
    padding-right:   20px;
    padding-bottom:  20px;
    display:         flex;
    flex-flow:       column;
    align-items:     stretch;
    justify-content: space-between;
    gap:             25px;

    @media (min-width: $screen-md)
    {
        flex-flow: row nowrap;
    }

    .factSheetFactsLeft,
    .factSheetFactsRight
    {
        width:       100%;
        color:       $colorGrayDarker;
        display:     flex;
        flex-flow:   column;
        align-items: flex-start;
    }

    .factsRow
    {
        display:     flex;
        flex-flow:   row nowrap;
        align-items: flex-end;
        color:       $colorGrayDark;
        font-size:   14px;
        width:       100%;

        .label
        {
            padding-right: 20px;
        }

        &:not(:first-child)
        {
            margin-top:  auto;
            padding-top: 15px;
        }

        .factsRowValue
        {
            color:        $colorGrayDarker;
            max-width:    50%;
            text-align:   right;
            padding-left: 20px;
        }

        .factsRowDottedLineWrapper
        {
            flex:      1;
            position:  relative;
            display:   flex;
            min-width: 10px;

            @media (min-width: $screen-md)
            {
                min-width: 50px;
            }

            .factsRowDottedLine
            {
                display:       flex;
                border-bottom: 1px dashed $colorGrayDark;
                position:      absolute;
                height:        80%;
                width:         100%;
                left:          0;
                top:           0;
            }
        }
    }
}
