//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.noSearchResultWrapper
{
    max-height:      50px;
    position:        relative;
    display:         flex;
    padding:         10px 20px;
    flex-direction:  row;
    align-items:     center;
    font-family:     $fontSourceOpenSans;
    font-size:       14px;
    white-space:     nowrap;
    overflow:        hidden;
    text-overflow:   ellipsis;
    justify-content: space-between;

    .keywordNotFoundWrapper
    {
        display:         flex;
        flex-direction:  row;
        justify-content: center;
        align-items:     center;
        gap:             12px;

        .keywordNotFoundText
        {
            color:          $colorBlackLight;
            font-size:      14px;
            text-transform: uppercase;
            font-weight:    $fontWeightBold;
        }
    }
}
