//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../styles/all';

.printContainer
{
    display:                    flex;
    flex-flow:                  column;
    width:                      297mm;
    min-width:                  297mm;
    max-width:                  297mm;
    height:                     210mm;
    min-height:                 210mm;
    max-height:                 210mm;
    padding:                    15px 30px;
    overflow:                   hidden;
    box-sizing:                 border-box;
    visibility:                 visible;
    position:                   absolute;
    -webkit-print-color-adjust: exact;

    *
    {
        color: $colorBlackLight !important;
    }

    h2,
    strong
    {
        font-size: 9.6px !important;
    }

    div
    {
        font-size: 8.2px !important;
    }

    .header
    {
        display:         flex;
        flex-flow:       row nowrap;
        justify-content: space-between;
        gap:             130px;
        height:          47px;
        margin-bottom:   25px;

        .companyOverview
        {
            display:          flex;
            flex-flow:        row nowrap;
            gap:              30px;
            flex:             1;
            padding:          8px 12px;
            background-color: $colorGray;

            + div
            {
                svg
                {
                    path
                    {
                        @include svgColorFill($colorGrayDark);
                    }
                }
            }
        }
    }

    .body
    {
        flex:        1;
        display:     flex;
        flex-flow:   row wrap;
        align-items: flex-start;

        .row
        {
            width:       100%;
            display:     flex;
            flex-flow:   row nowrap;
            align-items: stretch;

            > div
            {
                flex: 0 0 40%;

                &:nth-child(even)
                {
                    flex: 0 0 60%;
                }

                &:nth-child(2)
                {
                    border:           1px solid $colorGray;
                    background-color: transparent;
                }
            }

            .pies
            {
                display:   flex;
                flex-flow: row nowrap;

                > div
                {
                    background-color: transparent;
                }
            }

            canvas
            {
                max-height: 240px;
            }
        }
    }
}
