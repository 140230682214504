//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.startPageHeaderWrapper
{
    position:        relative;
    display:         flex;
    flex-flow:       row nowrap;
    align-items:     center;
    justify-content: space-between;
    box-sizing:      border-box;
    width:           100%;
    max-width:       $screen-lg;
    margin:          0 auto;
    padding:         25px 10px;

    @media (min-width: $screen-lg + 20px)
    {
        padding-left:  0;
        padding-right: 0;
    }

    .startPageHeaderLogoWrapper
    {
        width: 187px;
    }

    .startPageHeaderButtonWrapper
    {
        display:     flex;
        align-items: center;

        @media (max-width: $screen-sm-max)
        {
            position:         fixed;
            z-index:          $zIndexHeader;
            flex-flow:        column-reverse;
            justify-content:  center;
            top:              0;
            left:             0;
            width:            100%;
            height:           100%;
            transform:        translateX(100%);
            background-image: linear-gradient(262deg, $colorBlueCalypso, $colorBlueNileBlue);
        }

        &.contentOpen
        {
            transform: translateX(0);
        }

        > div
        {
            height: 40px;

            @media (max-width: $screen-sm-max)
            {
                margin-bottom: 20px;
            }
        }

        @media (min-width: $screen-md)
        {
            > *
            {
                margin-right: 15px;

                &:last-child
                {
                    margin-right: 0;
                }
            }
        }
    }

    .burgerMenu
    {
        position:    relative;
        z-index:     $zIndexHeaderBurger;
        display:     flex;
        flex-flow:   row nowrap;
        align-items: center;
        margin-left: auto;

        @media (min-width: $screen-md)
        {
            display: none;
        }

        button
        {
            width:  40px;
            height: 40px;
            border: none;

            > div
            {
                min-width:  40px;
                min-height: 40px;

                > span
                {
                    height: 100%;

                    svg
                    {
                        width:  100%;
                        height: 99%;

                        @include svgColorFill($colorOrange);
                    }
                }
            }
        }
    }
}
