//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../styles/all';

.companyProfileContainer
{
    display:          flex;
    flex-flow:        column;
    background-color: $colorWhite;

    @media (min-width: $screen-md)
    {
        flex-flow: row nowrap;
    }

    .companyProfileContent
    {
        flex:    1;
        padding: 23px 25px;

        .companyProfileDescription
        {
            margin-bottom: 35px;
        }
    }

    .companyProfileTagListContainer
    {
        width:      100%;
        padding:    27px 20px;
        border-top: 1px solid $colorGray;
        box-sizing: border-box;

        @media (min-width: $screen-md)
        {
            width:       263px;
            border-top:  0;
            border-left: 1px solid $colorGray;

            > *:nth-child(n+2)
            {
                margin-top: 122px;
            }
        }

        > *:nth-child(n+2)
        {
            margin-top: 30px;
        }
    }
}
