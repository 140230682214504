//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.wrapper
{
    background-color: $colorGrayLight;
    padding:          15px;
    display:          flex;
    align-items:      center;
    justify-content:  space-evenly;
    gap:              10px;

    & + .wrapper
    {
        margin-top: 20px;
    }

    .actions
    {
        flex:            1;
        display:         flex;
        justify-content: flex-end;

        .innerActions
        {
            flex-direction: column;
            gap:            10px;
            display:        flex;
        }
    }

    .left
    {
        display: flex;
    }

    .middle
    {
        display:     flex;
        align-items: center;
        flex:        1;
    }

    .right
    {
    }

    .match
    {
    }

    .status
    {
        .statusLabel
        {
            font-weight:  $fontWeightBold;
            margin-right: 5px;
        }

        .statusValue
        {
            font-weight: $fontWeightBold;

            &.colorBlack
            {
                color: $colorBlack;
            }

            &.colorOrange
            {
                color: $colorOrange;
            }

            &.colorRed
            {
                color: $colorRed;
            }
        }
    }
}
