//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.machinePreview
{
    cursor:           pointer;
    display:          flex;
    flex-flow:        row nowrap;
    box-sizing:       border-box;
    padding:          15px 13px 15px 20px;
    border-radius:    2px;
    border:           1px solid $colorGray;
    background-color: $colorWhite;
    margin-top:       10px;
    transition:       background-color 0.1s linear;

    &:hover
    {
        background-color: $colorOrangeLighter1;
    }

    &.machinePreviewSelected
    {
        position:         relative;
        background-color: $colorOrange;

        .machinePreviewSelectedIcon
        {
            position:  absolute;
            top:       50%;
            right:     13px;
            color:     $colorWhite;
            transform: translateY(-50%);
        }

        .machinePreviewText
        {
            color: $colorWhite;

            h4
            {
                color: $colorWhite;
            }
        }
    }

    &.machinePreviewFallbackImage
    {
        img
        {
            padding: 5px;
        }
    }

    &:first-child
    {
        margin-top: 0;
    }

    img
    {
        width:            70px;
        height:           70px;
        border:           1px solid $colorGray;
        border-radius:    2px;
        background-color: $colorWhite;
    }

    .machinePreviewText
    {
        display:         flex;
        flex-flow:       column;
        justify-content: space-between;
        font-family:     $fontSourceOpenSans;
        font-size:       14px;
        line-height:     1.43;
        color:           $colorGrayDarker;
        padding:         5px 0;
        overflow:        hidden;
        max-width:       100%;
        margin-left:     15px;

        h4
        {
            color:       $colorBlackLight;
            font-weight: $fontWeightExtraBold;
            transition:  color 0.1s linear;
        }

        p
        {
            overflow:      hidden;
            white-space:   nowrap;
            text-overflow: ellipsis;
            transition:    color 0.1s linear;
        }
    }
}
