//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.colorButton
{
    font-family:     $fontSourceOpenSans;
    font-weight:     $fontWeightExtraBold;
    text-align:      center;
    user-select:     none;
    display:         inline-flex;
    flex-flow:       row nowrap;
    align-items:     center;
    justify-content: center;
    white-space:     nowrap;
    width:           fit-content;
    border:          1px solid;
    border-radius:   2px;
    cursor:          pointer;
    text-decoration: none;
    transition:      color 0.1s linear,
                     border-color 0.1s linear,
                     background-color 0.1s linear;

    &[disabled]
    {
        cursor: default;
    }

    .iconWrapper
    {
        width:       14px;
        height:      14px;
        margin-left: 10px;
    }

    &.colorButtonGrow
    {
        flex:   1;
        margin: 0 5px;
        width:  100%;

        &:first-child
        {
            margin-left: 0;
        }

        &:last-child
        {
            margin-right: 0;
        }
    }

    &.colorButtonUppercase
    {
        text-transform: uppercase;
    }

    &.colorButtonSizeDefault
    {
        line-height: 40px;
        font-size:   14px;
        padding:     0 18px;
    }

    &.colorButtonSizeLarge
    {
        line-height: 40px;
        font-size:   18px;
        padding:     0 30px;
    }

    &.colorButtonSizeMedium
    {
        line-height: 40px;
        font-size:   12px;
        padding:     0 12px;

        @media (min-width: $screen-md)
        {
            line-height: 48px;
            font-size:   16px;
            padding:     0 24px;
        }
    }

    &.colorButtonOrange
    {
        background-color: $colorOrange;
        border-color:     $colorOrange;
        color:            $colorWhite;

        &:hover:not([disabled]):not(.colorButtonDisabled),
        &.colorButtonHover
        {
            background-color: $colorOrangeDark;
            border-color:     $colorOrangeDark;
        }

        &[disabled],
        &.colorButtonDisabled
        {
            background-color: $colorOrangeLight;
            border-color:     $colorOrangeLight;
        }
    }

    &.colorButtonRed
    {
        background-color: $colorRedCinnabar;
        border-color:     $colorRedCinnabar;
        color:            $colorWhite;

        &:hover:not([disabled]):not(.colorButtonDisabled),
        &.colorButtonHover
        {
            background-color: $colorRedFireEngineRed;
        }

        &[disabled],
        &.colorButtonDisabled
        {
            background-color: $colorRedRoseBud;
            border-color:     $colorRedRoseBud;
        }
    }

    &.colorButtonWhite
    {
        background-color: $colorWhite;
        border-color:     $colorGray;
        color:            $colorBlack;

        &:hover:not([disabled]):not(.colorButtonDisabled),
        &.colorButtonHover
        {
            background-color: $colorGrayLight;
            border-color:     $colorGrayLight;
        }

        &[disabled],
        &.colorButtonDisabled
        {
            color: $colorGrayHeather;
        }
    }

    &.colorButtonWhiteGhost
    {
        background-color: $colorTransparent;
        border-color:     $colorWhite;
        color:            $colorWhite;
        border-width:     2px;

        &:hover:not([disabled]):not(.colorButtonDisabled),
        &.colorButtonHover
        {
            background-color: $colorOrangeDark;
            border-color:     $colorOrangeDark;
        }

        &[disabled],
        &.colorButtonDisabled
        {
            border-color: $colorWhite50;
            color:        $colorWhite50;
        }
    }

    &.colorButtonTransparent
    {
        background-color: $colorTransparent;
        border-color:     $colorTransparent;
        color:            $colorWhite;

        &:hover:not([disabled]):not(.colorButtonDisabled),
        &.colorButtonHover
        {
            background-color: $colorOrangeDark;
            border-color:     $colorOrangeDark;
        }

        &[disabled],
        &.colorButtonDisabled
        {
            border-color: $colorWhite50;
            color:        $colorWhite50;
        }
    }

    &.colorButtonTransparentBorder
    {
        background-color: $colorTransparent;
        border-radius:    1px;
        border:           solid 2px $colorGray;
        color:            $colorBlackLight;

        &:hover:not([disabled]):not(.colorButtonDisabled),
        &.colorButtonHover
        {
            background-color: $colorOrangeDark;
            border-color:     $colorOrangeDark;
            color:            $colorWhite;
        }

        &[disabled],
        &.colorButtonDisabled
        {
            border-color: $colorWhite50;
            color:        $colorWhite50;
        }
    }
}
