//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.editFactSheetProductWrapper
{
    margin:    auto;
    padding:   24px 20px 20px;
    display:   flex;
    flex-flow: column nowrap;

    .editFactSheetProductFlex
    {
        display: flex;
        gap:     30px;

        .editFactSheetProductInputWrapper
        {
            > h3
            {
                margin-bottom: 6px;
            }
        }
    }
}
