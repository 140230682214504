//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.imagePreviewPhotoProvider
{
    z-index: $zIndexPhotoProvider !important;

    :nth-child(2)
    {
        z-index: $zIndexPhotoProvider !important;
    }
}

.imagePreview
{
    margin-top:     30px;
    display:        flex;
    flex-direction: row;
    gap:            10px;

    .imagePreviewImageWrapper
    {
        border:              1px solid #d9dadb;
        box-sizing:          border-box;
        border-radius:       5px;
        overflow:            hidden;
        background-color:    $colorWhite;
        background-position: center top 12px;
        padding:             3px;

        .imagePreviewImage
        {
            width:      75px;
            height:     75px;
            object-fit: cover;
        }
    }
}
