//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.colorBox
{
    padding: 20px;

    &.grayLight
    {
        background-color: $colorGrayLight;
    }

    &.white
    {
        background-color: $colorWhite;
    }

    &.whiteGrayBorder
    {
        padding:          0;
        border:           1px solid $colorGray;
        background-color: $colorWhite;
    }
}
