//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.startPageBackground
{
    position:            fixed;
    top:                 0;
    left:                0;
    width:               100%;
    height:              100%;
    background-image:    url('/assets/images/start-page-background.jpg');
    background-size:     cover;
    background-position: center;

    &::before
    {
        position:   absolute;
        z-index:    0;
        content:    '';
        top:        0;
        left:       0;
        width:      100%;
        height:     100%;
        background: $colorBlueCalypso80;
        background: linear-gradient(180deg, $colorBlueCalypso80 0%, $colorBlueNileBlue80 100%);
    }

    &.fullHeight
    {
        height: 100%;
    }
}
