//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.tagCertificate
{
    display: flex;
    gap:     6px;
    cursor:  pointer;

    > span:first-of-type
    {
        $size:  20px;

        width:  $size;
        height: $size;

        svg
        {
            path
            {
                @include svgColorFillImportant($colorGrayHeather);
                @include svgColorStrokeImportant($colorGrayHeather);
            }
        }
    }

    &.noDownload
    {
        pointer-events: none;
        cursor:         not-allowed;

        svg
        {
            opacity: 0.3;
        }
    }

    .tagTitle
    {
        font-weight: $fontWeightBold;
        font-size:   14px;
    }
}
