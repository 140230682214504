//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.messageCTA
{
    > div // This is the colorbox
    {
        display:         flex;
        flex-flow:       row nowrap;
        align-items:     center;
        justify-content: space-between;
        gap:             15px;

        .messageCTAIcon
        {
            flex:             0 0 70px;
            aspect-ratio:     1 / 1;
            border-radius:    2px;
            border:           solid 1px $colorGray;
            background-color: $colorWhite;

            > span
            {
                width:  100%;
                height: 100%;

                > svg
                {
                    width: 60%;

                    path
                    {
                        @include svgColorFillImportant($colorGrayHeather);
                    }
                }
            }
        }

        .messageCTAText
        {
            display:         flex;
            flex-flow:       column;
            justify-content: center;
            margin-right:    auto;

            p
            {
                font-size:   14px;
                line-height: 1.43;
                color:       $colorGrayDarker;
            }
        }

        > span
        {
            width:  12px;
            height: 12px;

            > svg
            {
                width: 100%;

                path
                {
                    @include svgColorFillImportant($colorGrayHeather);
                }
            }
        }
    }
}
