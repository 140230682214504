//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../styles/all';

.myProfileJoinCompanyContainer
{
    display:          flex;
    flex-flow:        row nowrap;
    background-color: $colorWhite;

    .myProfileJoinCompanyContent
    {
        flex:    1;
        padding: 23px 25px;

        h4
        {
            text-transform: uppercase;
        }

        p
        {
            font-family: $fontSourceOpenSans;
            font-size:   14px;
            font-weight: 400;
            line-height: 1.43;
            color:       $colorGrayDarker;
            margin-top:  10px;
        }
    }
}
