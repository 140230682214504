//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.break
{
    width:  100%;
    height: 0;
}

.factSheetProducts
{
    width:  100%;
    margin: auto auto 55px;

    .factSheetProductsHeadline
    {
        display:        flex;
        gap:            10px;
        font-size:      14px;
        font-weight:    $fontWeightExtraBold;
        letter-spacing: 0.5px;
        color:          $colorBlackLight;

        div > span
        {
            color: $colorGrayDarker;
        }

        .factSheetProductsHeadlineSubtext
        {
            font-size:  9px;
            align-self: center;
            color:      $colorGrayDark1;
        }
    }

    .factSheetProductsPreview
    {
        display:    flex;
        margin-top: 16px;
        flex-flow:  row wrap;
        gap:        15px;

        .factSheetProductsPreviewWrapper
        {
            flex:           0 0 calc(20% - 15px);
            position:       relative;
            display:        flex;
            flex-direction: column;

            .factSheetProductsPreviewButtonWrapper
            {
                position: absolute;
                top:      0;
                right:    0;
                width:    100%;
                height:   100%;

                .factSheetProductsPreviewButtonRow
                {
                    margin-top: 5px;
                    float:      right;

                    & > div
                    {
                        float:        right;
                        margin-right: 5px;
                    }
                }
            }

            .factSheetProductsPreviewContent
            {
                width:         100%;
                aspect-ratio:  1 / 1;
                border-radius: 2px;
                border:        solid 1px $colorGray;

                .factSheetProductsPreviewImage
                {
                    display:  flex;
                    width:    calc(100% - 6px);
                    height:   calc(100% - 6px);
                    overflow: hidden;
                    margin:   3px;
                }
            }

            .factSheetProductsPreviewTitle
            {
                margin:      10px 0 0;
                font-size:   14px;
                font-weight: bold;
                width:       100%;
                color:       $colorBlackLight;
            }
        }
    }
}
