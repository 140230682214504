//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.machineCapacityStatus
{
    display:   flex;
    flex-flow: row wrap;

    &.disabled
    {
        opacity: 0.8;
        cursor:  not-allowed;
    }

    .machineCapacityStatusDot
    {
        width:         10px;
        height:        10px;
        border-radius: 50%;
        margin:        5px 5px 5px 0;
    }

    .machineCapacityStatusText
    {
        display:   flex;
        flex-flow: row wrap;
    }
}
