//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.socialMediaList
{
    display:         flex;
    flex-flow:       row nowrap;
    align-items:     flex-end;
    justify-content: flex-end;
    list-style:      none;

    @media (min-width: $screen-lg + 20px)
    {
        margin-right: -10px;
    }

    li
    {
        margin-left:   24px;
        margin-bottom: -10px;

        a
        {
            padding: 10px;
            display: inline-block;
            width:   20px;
            height:  20px;

            > span
            {
                height: 100%;
            }

            svg
            {
                *
                {
                    @include svgColorFill($colorWhite);
                }
            }

            &:hover
            {
                svg
                {
                    *
                    {
                        @include svgColorFill($colorBlue);
                    }
                }
            }
        }
    }
}
