//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.signUpSearchResult
{
    position:         absolute;
    z-index:          1;
    // workaround as Safari is not supporting relative table rows
    top:              calc($tableHeaderRowHeight + (2 * $tableBodyRowHeight) + 30px);
    left:             50%;
    width:            80%;
    max-width:        60%;
    display:          flex;
    flex-flow:        row wrap;
    align-items:      center;
    justify-content:  space-between;
    gap:              20px;
    padding:          25px 35px 25px 30px;
    transform:        translateX(-50%);
    border-radius:    10px;
    border:           solid 1px $colorDodgerBlue;
    background-color: $colorWhite;

    @media (min-width: $screen-md)
    {
        max-width: 80%;
    }

    .signUpSearchResultContent
    {
        h3
        {
            span
            {
                color: $colorDodgerBlue;
            }
        }

        .signUpSearchResultText
        {
            display:   flex;
            flex-flow: row nowrap;

            a
            {
                display:        none;
                color:          $colorBlack;
                margin:         -9px 0;
                text-transform: uppercase;

                @media (min-width: $screen-md)
                {
                    display: flex;
                }

                svg
                {
                    path
                    {
                        @include svgColorFillImportant($colorGrayHeather);
                    }
                }
            }
        }
    }
}
