//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.link
{
    display:         inline-block;
    width:           40px;
    height:          40px;
    min-width:       100%;
    min-height:      100%;
    max-width:       100%;
    max-height:      100%;
    overflow:        hidden;
    border-radius:   100px;
    text-decoration: none;

    .avatar
    {
        display:      block;
        cursor:       pointer;
        object-fit:   contain;
        box-sizing:   border-box;
        width:        100%;
        height:       100%;
        aspect-ratio: 1 / 1;
        transition:   transform 0.1s linear;

        &:hover
        {
            transform: scale(1.4);
        }

        &.avatarFallback
        {
            display:          flex;
            align-items:      center;
            justify-content:  center;
            font-family:      $fontHKGrotesk;
            font-weight:      $fontWeightBold;
            font-stretch:     normal;
            font-style:       normal;
            line-height:      1.25;
            letter-spacing:   normal;
            text-align:       center;
            border-radius:    100px;
            color:            $colorBlackLight;
            border:           solid 1.7px $colorGray;
            background-color: $colorWhite;

            &.avatarFallbackSmall
            {
                font-size:  14px;
                width:      40px;
                height:     40px;
                min-width:  100%;
                min-height: 100%;
                max-width:  100%;
                max-height: 100%;
            }

            &.avatarFallbackDefault
            {
                font-size: 30px;
            }
        }
    }
}
