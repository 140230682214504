//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.headerUserButton
{
    position: relative;
    height:   100%;

    &:hover
    {
        .headerUserButtonDropDown
        {
            display: block;
        }
    }

    &.noCompany
    {
        margin-right: -10px;
    }

    .headerUserButtonBadge
    {
        > div
        {
            top:   0;
            right: -10px;
        }
    }

    .headerUserButtonDropDown
    {
        display:     none;
        position:    absolute;
        z-index:     $zIndexHeaderDropdown;
        top:         0;
        left:        calc(75% - 140px);
        padding-top: 48px;

        > div > button
        {
            width: 100%;

            &:last-child
            {
                margin-top: 10px;
            }
        }

        > div
        {
            padding: 10px !important;
        }

        > button
        {
            width:   100%;
            padding: 0 !important;
        }
    }

    .headerUserButtonText
    {
        display:         flex;
        flex-flow:       row nowrap;
        align-items:     center;
        padding:         11px 10px 10px 10px;
        font-family:     $fontSourceOpenSans;
        font-size:       14px;
        font-weight:     $fontWeightExtraBold;
        font-stretch:    normal;
        font-style:      normal;
        line-height:     1.43;
        letter-spacing:  normal;
        text-align:      center;
        color:           $colorWhite;
        white-space:     nowrap;
        text-decoration: none;
        cursor:          pointer;
        border:          0;
        background:      none;

        &:hover
        {
            .headerUserButtonIcon
            {
                svg
                {
                    *
                    {
                        @include svgColorFill($colorWhite);
                    }
                }
            }
        }

        .headerUserButtonIcon
        {
            width:       16px;
            height:      16px;
            margin-left: 10px;

            svg
            {
                *
                {
                    @include svgColorFill($colorWhite40);
                }
            }
        }
    }

    .headerUserButtonAvatar
    {
        aspect-ratio: 1 / 1;
    }
}
