//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.partner
{
    a
    {
        img
        {
            width:      auto;
            min-height: 20px;
            height:     2vh;
            opacity:    0.7;
            filter:     grayscale(1) invert(1) brightness(2);
            transition: filter 0.2s linear,
                        opacity 0.2s linear;
        }

        &:hover
        {
            img
            {
                opacity: 1;
                filter:  none;
            }
        }
    }
}
