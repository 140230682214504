//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@mixin svgColor($color)
{
    @include svgColorFill($color);
    @include svgColorStroke($color);
}

@mixin svgColorFill($color)
{
    fill: $color;
}

@mixin svgColorStroke($color)
{
    stroke: $color;
}

@mixin svgColorFillImportant($color)
{
    fill: $color !important;
}

@mixin svgColorStrokeImportant($color)
{
    stroke: $color !important;
}
