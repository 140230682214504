//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../styles/all';

.companyMessageContainer
{
    display:          flex;
    flex-flow:        row nowrap;
    background-color: $colorWhite;

    .companyMessageContent
    {
        width:   calc(100% - 50px);
        padding: 23px 25px;

        h3
        {
            margin-top:    20px;
            margin-bottom: 15px;
        }
    }
}
