//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

$transitionDuration: 0.2s;

.startPageSearchWrapper
{
    $borderRadius: 5px;

    width:         100%;
    background:    $colorWhite;
    object-fit:    contain;
    border-radius: $borderRadius;
    position:      relative;
    margin-bottom: 20px;
    box-shadow:    0 15px 25px 0 rgba(0, 0, 0, 0.06);

    &.startPageSearchWrapperWithContent
    {
        border-bottom-left-radius:  0;
        border-bottom-right-radius: 0;
    }

    &.startPageSearchWrapperHidden
    {
        .inputWrapper
        {
            max-height: 0;
            transition: max-height $transitionDuration linear $transitionDuration;

            input
            {
                &::placeholder
                {
                    color:      transparent;
                    transition: color $transitionDuration linear;
                }
            }

            .searchIconWrapper,
            .buttonGroupWrapper
            {
                opacity:    0;
                transition: opacity $transitionDuration linear;
            }
        }
    }

    .buttonWrapper
    {
        display:    inline-block;
        max-width:  200px;
        overflow:   hidden;
        transition: max-width 0.1s linear 0.2s,
                    margin-left 0.1s linear 0.2s;

        & + .buttonWrapper
        {
            margin-left: 8px;
        }

        &.buttonWrapperHidden
        {
            max-width:   0;
            margin-left: 0;
            transition:  max-width 0.1s linear,
                         margin-left 0.1s linear;
        }
    }

    .alertBoxWrapper
    {
        padding: 10px 20px;
    }

    .startPageWrapper
    {
        position: absolute;
        z-index:  1;
        width:    100%;
    }

    .detailsWrapper
    {
        border-top:                 1px solid $colorGray;
        background:                 $colorWhite;
        border-bottom-left-radius:  $borderRadius;
        border-bottom-right-radius: $borderRadius;
        padding:                    0 0 $borderRadius 0;
        overflow:                   auto;
        box-shadow:                 0 15px 25px 0 rgba(0, 0, 0, 0.06);
        transition:                 max-height $transitionDuration linear;

        @media (max-width: $screen-sm-max)
        {
            max-height: calc(100vh - 300px) !important;
        }

        &.detailsWrapperHide
        {
            display: none;
        }

        &.detailsWrapperReduced
        {
            display:       none;
            border-radius: $borderRadius;

            &.detailsWrapperTagList
            {
                display: inherit;
            }
        }

        &.detailsWrapperOverlay
        {
            &.detailsWrapperOverlayWithResults
            {
                box-shadow: 0 15px 25px 0 rgba(0, 0, 0, 0.5);
            }
        }
    }

    .detailsWrapperShowMoreButton
    {
        position: absolute;
        top:      8px;
        right:    8px;
    }

    .inputWrapper
    {
        position:   relative;
        width:      100%;
        height:     56px;
        max-height: 56px;
        overflow:   hidden;
        transition: max-height $transitionDuration linear;

        .searchIconWrapper
        {
            position:       absolute;
            top:            50%;
            left:           20px;
            width:          16px;
            height:         16px;
            opacity:        1;
            transform:      translateY(-50%);
            transition:     opacity $transitionDuration linear $transitionDuration;
            pointer-events: none;

            span
            {
                display: block;

                svg
                {
                    *
                    {
                        @include svgColorFill($colorGrayDarkBlue);
                    }
                }
            }
        }

        input
        {
            width:       100%;
            height:      100%;
            border:      0;
            padding:     8px 20px 10px 56px;
            box-sizing:  border-box;
            background:  transparent;
            color:       $colorGrayDarker;
            font-family: $fontSourceOpenSans;
            font-weight: $fontWeightSemiBold;
            font-size:   16px;

            &::placeholder
            {
                color:      $colorGrayDarkBlue;
                transition: color $transitionDuration linear $transitionDuration;
            }
        }

        .buttonGroupWrapper
        {
            position:   absolute;
            top:        50%;
            right:      8px;
            opacity:    1;
            transform:  translateY(-50%);
            transition: opacity $transitionDuration linear $transitionDuration;
        }
    }

    &.startPageSearchWrapperCompany
    {
        box-shadow: none;
        border:     1px solid $colorGray;

        .searchIconWrapper
        {
            left:  unset;
            right: 20px;
        }

        .inputWrapper
        {
            input
            {
                padding-left:  20px;
                padding-right: 56px;
            }
        }
    }
}

.tagSearchButtonContainer
{
    display:       flex;
    gap:           10px;
    align-items:   center;
    margin-bottom: 20px;

    > span
    {
        color:       $colorWhite;
        font-size:   18px;
        font-weight: $fontWeightSemiBold;
    }
}
