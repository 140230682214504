//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.machineBoxWrapper
{
    width:            100%;
    display:          flex;
    flex-flow:        column;
    background-color: $colorWhite;

    @media (min-width: $screen-sm)
    {
        flex-flow: row nowrap;
    }

    .machineBoxLeftContent
    {
        flex:      3.2;
        display:   flex;
        flex-flow: column;

        .machineBoxTopLeftContent
        {
            padding: 25px 25px 30px 25px;
        }

        .machineBoxBottomLeftContent
        {
            padding: 0 25px 30px 25px;
        }
    }

    .machineBoxRightContent
    {
        flex:        1;
        padding:     25px 25px 30px 25px;
        border-left: 1px solid $colorGray;
    }
}
