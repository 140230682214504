//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.startPageBox
{
    width:            360px;
    max-width:        calc(100% - 20px);
    display:          flex;
    flex-flow:        column;
    padding:          25px 35px;
    margin:           30px 10px 0 10px;
    border-radius:    5px;
    box-shadow:       0 2px 9px 0 $colorBlackPitch50;
    background-color: $colorWhite;
    box-sizing:       border-box;

    @media (min-width: $screen-md)
    {
        margin-top: 0;
    }

    h2
    {
        margin-bottom: 25px;
    }
}
