//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.signupButtonWrapper
{
    width:      50%;
    margin-top: 29px;
}

.legalText
{
    color:       $colorGrayDarker;
    font-size:   14px;
    font-weight: normal;

    a
    {
        text-decoration: underline;
    }
}
