//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.industryRatioInputWrapper
{
    background: $colorWhite;

    .industryRatioInputHeader
    {
        margin: 0 0 15px 0;
    }

    .industryRatioInputList
    {
        .industryRatioRow
        {
            margin-bottom: 25px;
        }

        .industryRatioInputRow
        {
            position:       relative;
            display:        flex;
            flex-direction: column;
            margin:         0 0 6px 0;

            @media (min-width: $screen-md)
            {
                flex-direction: row;
            }

            &:last-child
            {
                margin-bottom: 0;
            }

            .industryRatioInputColumn,
            .industryRatioInputColumnInput,
            .industryRatioInputColumnPercent
            {
                display:     flex;
                align-items: center;

                @media (min-width: $screen-md)
                {
                    padding: 0 20px 0 0;
                }

                &:nth-child(1)
                {
                    flex: 4;
                }

                &:nth-child(2)
                {
                    flex: 1;
                }

                &:nth-child(3)
                {
                    padding-right: 10px;
                }

                h3
                {
                    margin-bottom: 6px;
                }

                & > *
                {
                    width: 100%;
                }

                @media (max-width: $screen-sm-max)
                {
                    svg
                    {
                        margin-top: 10px;
                    }
                }
            }

            @media (max-width: $screen-sm-max)
            {
                .industryRatioInputColumnPercent
                {
                    position: absolute;
                    right:    0;
                }
            }

            @media (max-width: $screen-sm-max)
            {
                .industryRatioInputColumnInput
                {
                    margin-top: 10px;
                }
            }
        }
    }
}
