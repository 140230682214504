//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.addNewMachineHeaderContainer
{
    margin-bottom: 40px;
}

.addNewMachineBodyContainer
{
    .addNewMachineBodyBaseContainer
    {
        display:   flex;
        flex-flow: row nowrap;

        .addNewMachineBodyImageContainer
        {
            margin-right: 30px;
        }

        .addNewMachineBodyTextContainer
        {
            flex:      1;
            display:   flex;
            flex-flow: column;

            .addNewMachineBodyDescriptionContainer
            {
                margin-top: 15px;
            }
        }
    }

    .addNewMachineInputContainer
    {
        .addNewMachineInputInnerContainer
        {
            display:     flex;
            flex-flow:   row wrap;
            align-items: flex-end;
            margin-top:  20px;

            &:first-child
            {
                margin-top: 0;
            }

            > *
            {
                flex: 1 1 200px;

                &:nth-child(n+2)
                {
                    margin-left: 20px;
                }

                &:last-child
                {
                    flex:          0;
                    margin-bottom: 10px;

                    svg
                    {
                        color:  $colorWhite;
                        width:  10px;
                        height: 10px;
                    }
                }
            }
        }
    }
}
