//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../styles/all';

.contentContainer
{
    margin-left: 0 !important;

    .favorites
    {
        $gap:            20px;

        width:           100%;
        display:         flex;
        flex-flow:       row;
        flex-wrap:       wrap;
        justify-content: space-between;
        gap:             $gap;

        > *
        {
            width: 100%;

            @media (min-width: $screen-md)
            {
                width: calc(50% - #{$gap * 0.5});
            }
        }
    }
}
