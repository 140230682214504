//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.addressBoxWrapper
{
    .addressBoxList
    {
        display:        flex;
        flex-flow:      row wrap;
        margin-top:     10px;
        font-family:    $fontSourceOpenSans;
        font-size:      14px;
        font-weight:    $fontWeightRegular;
        font-stretch:   normal;
        font-style:     normal;
        line-height:    1.43;
        letter-spacing: normal;

        dt
        {
            display:     flex;
            align-items: baseline;
            flex:        0 0 154px;
            color:       $colorGrayDark;
            margin-top:  5px;

            &::after
            {
                flex:          1;
                content:       '';
                margin-left:   18px;
                margin-right:  20px;
                border-bottom: 1px dashed $colorGray;
            }
        }

        dd
        {
            flex:       0 0 calc(100% - 154px);
            color:      $colorGrayDarker;
            margin-top: 5px;
        }
    }

    .addressBoxMapWrapper
    {
        height:     212px;
        margin-top: 20px;
    }
}
