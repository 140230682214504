//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.contentHeaderTitleWrapper
{
    display:   flex;
    flex-flow: row nowrap;

    .contentHeaderTitleTextContainer
    {
        display:         flex;
        flex-flow:       column;
        justify-content: space-between;
        padding:         0 6px;
        margin-left:     15px;

        .contentHeaderTitleTextTitle
        {
            display:        flex;
            flex-flow:      row nowrap;
            align-items:    center;
            font-size:      21px;
            line-height:    1.43;
            font-family:    $fontSourceOpenSans;
            font-weight:    $fontWeightExtraBold;
            font-stretch:   normal;
            font-style:     normal;
            letter-spacing: normal;
            color:          $colorBlackLight;
        }

        .contentHeaderTitleTextSubtitle
        {
            font-size:      18px;
            line-height:    1.25;
            font-family:    $fontSourceOpenSans;
            font-weight:    $fontWeightRegular;
            font-stretch:   normal;
            font-style:     normal;
            letter-spacing: normal;
            color:          $colorGrayDark;
            margin-top:     3px;
        }
    }
}
