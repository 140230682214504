//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.contentHeaderWrapper
{
    position:         relative;
    display:          flex;
    flex-flow:        column-reverse;
    justify-content:  space-between;
    padding:          24px 25px;
    box-sizing:       border-box;
    min-height:       104px;
    gap:              20px;
    border-bottom:    1px solid $colorGray;
    background-color: $colorWhite;

    @media (min-width: $screen-md)
    {
        flex-flow: row nowrap;

        .contentHeaderLeft
        {
            margin: -6px 0;
        }
    }

    .contentHeaderRight
    {
        display:         flex;
        flex-flow:       column;
        align-items:     flex-end;
        justify-content: space-between;
        min-width:       170px;
        margin-left:     auto;

        @media (min-width: $screen-md)
        {
            margin-left: inherit;
        }

        .contentHeaderRightContent
        {
            display:     flex;
            flex-flow:   row nowrap;
            align-items: center;

            > *
            {
                margin-left: 12px;
            }
        }

        .contentHeaderInfoText
        {
            font-family:    $fontSourceOpenSans;
            font-size:      12px;
            font-weight:    $fontWeightRegular;
            font-stretch:   normal;
            font-style:     normal;
            line-height:    1.25;
            letter-spacing: normal;
            text-align:     right;
            color:          $colorGrayDark;
            margin-bottom:  10px;

            @media (min-width: $screen-md)
            {
                margin-top:    10px;
                margin-bottom: 0;
            }
        }
    }
}
