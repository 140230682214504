//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.headerContainer
{
    position:         relative;
    z-index:          $zIndexHeader;
    width:            100%;
    display:          flex;
    align-items:      stretch;
    justify-content:  space-between;
    min-height:       76px;
    background-image: linear-gradient(262deg, $colorBlueCalypso, $colorBlueNileBlue);

    .headerInnerContainer
    {
        width:      100%;
        display:    flex;
        flex-flow:  column;
        margin:     0 auto;
        max-width:  $screen-lg;
        box-sizing: border-box;
        padding:    0 10px;

        @media (min-width: $screen-lg + 20px)
        {
            padding-left:  0;
            padding-right: 0;
        }

        .headerInnerHeaderContainer
        {
            height:      100%;
            display:     flex;
            flex-flow:   row nowrap;
            align-items: center;
            padding:     18px 0;

            @media (min-width: $screen-md)
            {
                padding: 0;
            }

            .headerLogoContainer
            {
                width: 120px;

                a
                {
                    min-width: 90px;
                }
            }

            .burgerMenu
            {
                position:    relative;
                z-index:     $zIndexHeaderBurger;
                display:     flex;
                flex-flow:   row nowrap;
                align-items: center;
                margin-left: auto;

                @media (min-width: $screen-md)
                {
                    display: none;
                }

                button
                {
                    width:  40px;
                    height: 40px;
                    border: none;

                    &:first-child
                    {
                        width:        20px;
                        height:       20px;
                        margin-right: 10px;

                        > div
                        {
                            min-width:  20px;
                            min-height: 20px;

                            svg
                            {
                                path
                                {
                                    @include svgColorFill($colorWhite);
                                }
                            }
                        }
                    }

                    > div
                    {
                        min-width:  35px;
                        min-height: 35px;

                        > span
                        {
                            height: 100%;

                            svg
                            {
                                width:  100%;
                                height: 99%;

                                @include svgColorFill($colorOrange);
                            }
                        }
                    }
                }
            }

            .inputWrapper
            {
                position:  relative;
                width:     430px;
                max-width: calc(100% - 20px);
                height:    40px;
                margin:    30px 30px 0 30px;

                @media (min-width: $screen-md)
                {
                    flex:       1;
                    margin-top: 0;
                }

                input
                {
                    width:            100%;
                    height:           100%;
                    border:           0;
                    padding:          10px 55px 10px 25px;
                    color:            $colorGrayDarker;
                    font-family:      $fontSourceOpenSans;
                    font-weight:      $fontWeightRegular;
                    font-size:        16px;
                    box-sizing:       border-box;
                    border-radius:    2px;
                    background-color: $colorWhite;

                    &::placeholder
                    {
                        color: $colorGrayDarkBlue;
                    }
                }

                .inputWrapperIcon
                {
                    position:  absolute;
                    top:       50%;
                    right:     24px;
                    width:     17px;
                    height:    17px;
                    transform: translateY(-50%);

                    svg
                    {
                        *
                        {
                            @include svgColorFill($colorGrayHeather);
                        }
                    }
                }
            }

            .content
            {
                width:       100%;
                display:     flex;
                flex-flow:   row nowrap;
                align-items: center;
                min-height:  76px;

                @media (max-width: $screen-sm-max)
                {
                    position:         fixed;
                    flex-flow:        column-reverse;
                    justify-content:  center;
                    top:              0;
                    left:             0;
                    width:            100%;
                    height:           100%;
                    transform:        translateX(100%);
                    background-image: linear-gradient(262deg, $colorBlueCalypso, $colorBlueNileBlue);
                }

                &.contentOpen
                {
                    z-index:   1;
                    transform: translateX(0);
                }

                .navigationContent
                {
                    display:         flex;
                    flex-flow:       column-reverse;
                    align-items:     center;
                    justify-content: flex-end;
                    box-sizing:      border-box;
                    min-height:      76px;
                    margin-bottom:   20px;

                    @media (min-width: $screen-md)
                    {
                        flex:          1;
                        height:        100%;
                        flex-flow:     row nowrap;
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}
