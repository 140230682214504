//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.addNewMachineHeaderContainer
{
    h2
    {
        margin-bottom: 10px;
    }

    p
    {
        font-family: $fontSourceOpenSans;
        font-size:   14px;
        line-height: 1.43;
        color:       $colorGrayDarker;
    }
}

.addNewMachineBodyContainer
{
    padding-bottom: 35px;

    .addNewMachineSearchContainer
    {
        display:       flex;
        flex-flow:     row nowrap;
        margin-top:    25px;
        margin-bottom: 25px;

        input
        {
            max-width: 320px;
        }

        button
        {
            margin-left: 10px;
        }
    }

    .addNewMachineResultContainer
    {
        overflow:   auto;
        max-height: 310px;

        .addNewMachineResultEmptyContainer
        {
            border:     1px solid $colorGray;
            box-sizing: border-box;

            &:last-child
            {
                margin-top: 10px;
            }
        }
    }
}

.loadingIndicator
{
    animation: rotation_animation 1.3s infinite linear;
}

@keyframes rotation_animation
{
    0%
    {
        transform: rotate(0deg);
    }

    100%
    {
        transform: rotate(360deg);
    }
}
