//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../styles/all';

.membershipContainer
{
    flex:             1;
    padding:          0 10px 100px 10px;
    background-color: $colorWhiteDark;

    @media (min-width: $screen-md)
    {
        padding: 0 0 70px 0;
    }
}
