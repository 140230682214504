//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.starButtonWrapper
{
    cursor:      pointer;
    margin-left: 10px;

    svg
    {
        *
        {
            @include svgColorStroke($colorOrange);
        }
    }

    &.starButtonWrapperHover
    {
        &:hover
        {
            svg
            {
                *
                {
                    @include svgColorFill($colorOrange);
                }
            }
        }
    }

    &.starButtonWrapperGray
    {
        svg
        {
            *
            {
                @include svgColorFill($colorGrayHeather);
                @include svgColorStroke($colorGrayHeather);
            }
        }

        &.starButtonWrapperHover
        {
            &:hover
            {
                svg
                {
                    *
                    {
                        @include svgColorFill($colorOrange);
                        @include svgColorStroke($colorOrange);
                    }
                }
            }
        }
    }

    &.starButtonWrapperGlow
    {
        svg
        {
            *
            {
                @include svgColorFill($colorOrange);
            }
        }

        &.starButtonWrapperHover
        {
            &:hover
            {
                svg
                {
                    *
                    {
                        @include svgColorFill($colorTransparent);
                    }
                }
            }
        }
    }
}
