//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.capacityInputWrapper
{
    .capacityInputHeader
    {
        margin: 0 0 15px 0;
    }

    .capacityInputList
    {
        .capacityRow
        {
            margin-bottom: 25px;
        }

        .capacityInputRow
        {
            display:        flex;
            flex-direction: column;
            margin:         0 0 10px 0;

            &:last-child
            {
                margin-bottom: 0;
            }

            .capacityInputColumn
            {
                padding:     0 20px 0 0;
                display:     flex;
                align-items: center;

                &:nth-child(1)
                {
                    flex: 2;
                }

                &:nth-child(2)
                {
                    flex: 2;
                }

                & > *
                {
                    width: 100%;
                }
            }
        }
    }
}
