//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.headerButton
{
    position: relative;
    height:   58px;

    @media (min-width: $screen-md)
    {
        align-self:   self-end;
        margin-right: 20px;
    }

    @media (min-width: $screen-lg)
    {
        margin-right: 27px;
    }

    &:hover
    {
        &::before
        {
            height: 5px;
        }
    }

    &::before
    {
        position:         absolute;
        content:          '';
        bottom:           0;
        left:             10px;
        right:            10px;
        height:           0;
        background-color: $colorOrange;
        transition:       height 0.1s linear;
    }

    &.headerButtonActive
    {
        &::before
        {
            height: 5px;
        }

        .headerButtonText
        {
            font-weight: $fontWeightExtraBold;
        }
    }

    .headerButtonText
    {
        display:         block;
        padding:         10px;
        font-family:     $fontSourceOpenSans;
        font-size:       18px;
        font-weight:     $fontWeightRegular;
        font-stretch:    normal;
        font-style:      normal;
        line-height:     1.11;
        letter-spacing:  normal;
        color:           $colorWhite;
        white-space:     nowrap;
        text-decoration: none;

        @media (min-width: $screen-md)
        {
            font-size: 12px;
        }

        @media (min-width: $screen-lg)
        {
            font-size: 18px;
        }
    }

    .headerButtonBadge
    {
        > div
        {
            top:   5px;
            right: -15px;
        }
    }
}
