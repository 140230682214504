//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../styles/all';

.loginInputWrapper
{
    display:   flex;
    flex-flow: column;
    gap:       20px;
}

.loginButtonWrapper
{
    display:         flex;
    flex-flow:       row nowrap;
    justify-content: space-between;
    margin-top:      29px;
}
