//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../styles/all';

.companyMessageDetailContainer
{
    display:          flex;
    flex-flow:        row nowrap;
    background-color: $colorWhite;

    .companyMessageDetailContent
    {
        width:   100%;
        padding: 23px 25px;

        > h2
        {
            text-transform: uppercase;
        }

        .companyMessageDetailContentTag
        {
            margin-top:  10px;
            margin-left: 10px;
            color:       $colorGray;
            font-size:   12px;
        }

        .companyMessageSendContainer
        {
            text-transform: none;
            margin-top:     24px;
            display:        flex;
            flex-direction: column;
            align-items:    flex-end;

            .companyMessageSendTextInput
            {
                width:      100%;
                margin-top: 14px;

                @media (min-width: $screen-md)
                {
                    width: calc(100% - 193px);
                }
            }

            > button
            {
                margin-top: 15px;
            }

            .sendTextButtonRow
            {
                display:         flex;
                flex-direction:  row;
                align-items:     center;
                justify-content: flex-end;
                margin-top:      15px;
                gap:             23px;

                .sendTextButton
                {
                    margin-left: 10px;
                }
            }

            .attachmentListWrapper
            {
                display: flex;
                width:   calc(100% - 193px);
            }

            .messageRequestButtons
            {
                display:     flex;
                flex-flow:   row nowrap;
                align-items: center;
                gap:         20px;
                margin:      auto 20px auto auto;

                > button
                {
                    width: 90px;
                }
            }
        }

        .messageContainer
        {
            margin-top:       15px;
            margin-right:     10%;
            background-color: $colorGrayLight;
            border-radius:    10px;
            padding:          20px;

            @media (min-width: $screen-md)
            {
                margin-right: 193px;
            }

            &.messageContainerRight
            {
                margin-left:      10%;
                margin-right:     0;
                border-radius:    10px;
                border:           solid 1px $colorGray;
                background-color: $colorWhite;

                @media (min-width: $screen-md)
                {
                    margin-left: 193px;
                }
            }

            .messageTextContainer
            {
                margin-top:  15px;
                font-family: $fontSourceOpenSans;
                font-size:   18px;
                line-height: 1.67;
                color:       $colorGrayDarker;
                white-space: pre-wrap;

                a
                {
                    text-decoration: underline;
                }

                a:link
                {
                    color: $colorDodgerBlue;
                }

                a:visited
                {
                    color: $colorPurple;
                }
            }
        }
    }

    .companyMessageTranslatedWith
    {
        margin-top: 17px;
        font-size:  12px;
        color:      $colorGrayDark;
    }
}
