//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.parameterInputWrapper
{
    background: $colorWhite;

    .parameterInputHeader
    {
        margin: 0 0 15px 0;
    }

    .parameterInputParameterList
    {
        .parameterInputRow
        {
            display:        flex;
            flex-direction: row;
            margin:         0 0 6px 0;

            &:last-child
            {
                margin-bottom: 0;
            }

            .parameterInputColumn
            {
                padding:     0 20px 0 0;
                display:     flex;
                align-items: center;

                &:nth-child(1)
                {
                    flex: 45;
                }

                &:nth-child(2)
                {
                    flex: 45;
                }

                &:nth-child(3)
                {
                    flex:          10;
                    padding-right: 0;
                }

                h3
                {
                    margin-bottom: 6px;
                }
            }
        }
    }
}
