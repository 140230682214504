//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.magicBanner
{
    box-sizing:       border-box;
    position:         fixed;
    z-index:          $zIndexCookieBanner;
    display:          flex;
    flex-flow:        row nowrap;
    align-items:      flex-start;
    justify-content:  space-between;
    width:            100%;
    bottom:           0;
    padding:          20px 40px;
    background-color: $colorWhite;

    .magicBannerText
    {
    }

    @media only screen and (max-width: $screen-sm)
    {
        height:    100%;
        flex-flow: column;

        .magicBannerText
        {
            height:        100%;
            overflow:      hidden;
            width:         100%;
            margin-bottom: 40px;
        }
    }

    &::before
    {
        content:          '';
        position:         absolute;
        left:             0;
        bottom:           100%;
        z-index:          -1;
        width:            100%;
        height:           100vh;
        background-color: $colorBlackPitch25;
    }

    .magicBannerButtons
    {
        display:   flex;
        flex-flow: row nowrap;
        gap:       20px;

        @media screen and (max-width: $screen-sm)
        {
            position: absolute;
            bottom:   10px;
            left:     50px;
        }
    }

    p
    {
        white-space: pre-line;
        position:    relative;

        @media screen and (max-width: $screen-sm)
        {
            overflow:   hidden;
            overflow-y: scroll;
            height:     calc(100% - 75px);
        }

        a
        {
            color: $colorOrange;
        }
    }
}
