//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.pieChartLegendRow
{
    display:         flex;
    flex-flow:       row nowrap;
    align-items:     flex-start;
    justify-content: space-between;

    :not(:last-child)
    {
        margin-bottom: 10px;
    }

    .pieChartLegendRowName
    {
        font-size: 14px;
    }

    .pieChartLegendRowValueWrapper
    {
        display:     flex;
        align-items: center;
        font-size:   14px;
        font-weight: $fontWeightExtraBold;

        .pieChartLegendRowValue
        {
            margin-left: 10px;
            min-width:   60px;
        }

        .pieChartLegendRowValuePoint
        {
            margin:        auto;
            width:         10px;
            height:        10px;
            border-radius: 50%;
        }
    }
}
