//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.roundDropDownCaretDownWrapper
{
    width:   10px;
    height:  5px;
    display: inline-block;

    &.roundDropDownCaretDownWrapperReduced
    {
        width:  20px;
        height: 20px;

        svg
        {
            path
            {
                @include svgColorFillImportant($colorGrayDark1);
            }
        }
    }

    svg
    {
        path
        {
            @include svgColorFillImportant($colorBlack);
        }
    }
}
