//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.deleteMachineHeaderContainer
{
    h2
    {
        font-family:   $fontHKGrotesk;
        font-size:     20px;
        font-weight:   $fontWeightBold;
        line-height:   1.25;
        color:         $colorBlackLight;
        margin-bottom: 10px;
    }
}

.deleteMachineBodyContainer
{
    font-family: $fontSourceOpenSans;
    font-size:   14px;
    line-height: 1.43;
    color:       $colorGrayDarker;
    padding:     0 40px;

    strong
    {
        font-weight: $fontWeightExtraBold;
    }
}
