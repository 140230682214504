//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.tableHeaderColumn
{
    box-sizing:     border-box;
    border-bottom:  1px solid $colorGray;
    font-family:    $fontSourceOpenSans;
    font-size:      14px;
    font-weight:    $fontWeightRegular;
    font-stretch:   normal;
    font-style:     normal;
    line-height:    1.43;
    letter-spacing: normal;
    color:          $colorGrayDarker;

    &:last-child
    {
        padding-right: 25px;
    }

    .tableHeaderColumnInnerContainer
    {
        display: block;
    }
}
