//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

h3.headline
{
    display:   flex;
    flex-flow: row nowrap;

    .headlineSmall
    {
        display:        flex;
        flex-flow:      row nowrap;
        font-family:    $fontSourceOpenSans;
        font-weight:    $fontWeightExtraBold;
        font-size:      14px;
        line-height:    1.43;
        letter-spacing: 0.5px;
        color:          $colorBlackLight;
        text-transform: uppercase;

        &.headlineClickable
        {
            cursor:      pointer;
            user-select: none;
        }

        &.headlineClickableDisabled
        {
            cursor:      not-allowed;
            user-select: none;
            opacity:     0.8;
        }

        > span
        {
            width:       auto;
            margin-left: 10px;
        }
    }

    .headlineSmallChildren
    {
        margin-left: auto;
    }
}

div + .sideMenuHeadline
{
    margin-top: 20px;
}
