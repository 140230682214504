//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.tableBodyColumn
{
    box-sizing:     border-box;
    min-height:     30px;
    line-height:    30px;
    padding:        0 15px;
    font-family:    $fontSourceOpenSans;
    font-size:      14px;
    font-weight:    $fontWeightExtraBold;
    font-stretch:   normal;
    font-style:     normal;
    letter-spacing: normal;
    color:          $colorBlackLight;

    &:first-child
    {
        margin-top: 3px;
    }

    @media (min-width: $screen-md)
    {
        padding:     0 35px;
        line-height: 1.43;

        &:first-child
        {
            margin-top:   0;
            padding-left: 25px;
        }
    }

    &:last-child
    {
        width:         1%;
        white-space:   nowrap;
        padding-right: 25px;

        @media (max-width: $screen-sm-max)
        {
            position:  absolute;
            top:       50%;
            right:     30px;
            transform: translateY(-50%);
        }
    }
}
