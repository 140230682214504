//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.pieChartWrapper
{
    margin: auto;

    .pieChartContentWrapper
    {
        display:        flex;
        flex-direction: column;
        align-items:    center;

        .pieChartContent
        {
            height:        170px;
            width:         170px;
            position:      relative;
            margin-bottom: 24px;

            .pieChartLabelWrapper
            {
                position:   absolute;
                top:        0;
                left:       0;
                width:      100%;
                height:     100%;
                padding:    4px;
                box-sizing: border-box;
                display:    flex;

                .pieChartLabelContent
                {
                    margin:          auto;
                    display:         flex;
                    width:           100%;
                    height:          100%;
                    align-items:     center;
                    justify-content: center;
                    flex-direction:  column;
                    font-weight:     $fontWeightExtraBold;

                    .pieChartLabelTotal
                    {
                        font-size: 30px;
                    }

                    .pieChartLabelText
                    {
                        font-size: 18px;
                    }
                }
            }
        }
    }

    .pieChartLegend
    {
        display:        flex;
        flex-direction: column;
        margin-left:    25px;
        margin-right:   25px;
        margin-bottom:  20px;
    }
}
