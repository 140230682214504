//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../styles/all';

.myProfileCurrentRoleContainer
{
    display:          flex;
    flex-flow:        row nowrap;
    background-color: $colorWhite;
    margin-bottom:    30px;

    .myProfileCurrentRoleContent
    {
        flex:    1;
        padding: 23px 25px;

        .currentRoleContainer
        {
            background:      $colorGrayLight;
            padding:         10px 20px;
            container-type:  inline-size;
            container-name:  currentRoleContainer;
            display:         flex;
            justify-content: center;
            align-items:     center;

            .infoContainer
            {
                width:     50%;
                display:   flex;
                flex-flow: column;
                gap:       20px;

                /* stylelint-disable-next-line scss/at-rule-no-unknown */
                @container currentRoleContainer (min-width: #{$currentRoleContainerBreakpoint})
                {
                    width:       calc((2 / 3) * 100%);
                    flex-flow:   row;
                    align-items: center;
                }

                .roleInfoContainer
                {
                    /* stylelint-disable-next-line scss/at-rule-no-unknown */
                    @container currentRoleContainer (min-width: #{$currentRoleContainerBreakpoint})
                    {
                        width: 50%;
                    }

                    span:first-child
                    {
                        font-weight: $fontWeightBlack;
                    }

                    span:last-child
                    {
                        font-weight: $fontWeightBlack;
                        color:       $colorOrange;
                    }
                }

                .userInfoContainer
                {
                    display:     flex;
                    gap:         10px;
                    align-items: center;

                    /* stylelint-disable-next-line scss/at-rule-no-unknown */
                    @container currentRoleContainer (min-width: #{$currentRoleContainerBreakpoint})
                    {
                        width:           50%;
                        justify-content: center;
                    }

                    .avatarWrapper
                    {
                        $size:       45px;

                        flex-shrink: 0;
                        width:       $size;
                        height:      $size;
                    }

                    .userWrapper
                    {
                        div:first-child
                        {
                            font-weight: $fontWeightBlack;
                            display:     flex;
                            width:       fit-content;
                            align-items: center;
                            gap:         10px;
                        }

                        div:last-child
                        {
                            font-weight: $fontWeightLight;
                            color:       $colorGrayDark;
                            font-size:   12px;
                        }
                    }
                }
            }

            .currentRoleActionContainer
            {
                width:       50%;
                display:     flex;
                flex-flow:   column;
                align-items: flex-end;
                gap:         10px;

                /* stylelint-disable-next-line scss/at-rule-no-unknown */
                @container currentRoleContainer (min-width: #{$currentRoleContainerBreakpoint})
                {
                    width: calc((1 / 3) * 100%);
                }
            }
        }
    }
}
