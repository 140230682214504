//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.requestMessageFormWrapper
{
    margin-top: 60px;

    .requestMessageFormHeadline
    {
        text-transform: uppercase;
        font-weight:    $fontWeightExtraBold;
    }

    .requestMessageFormButtons
    {
        display:    flex;
        margin-top: 35px;

        > button
        {
            margin-right: 10px;
        }
    }

    .requestMessageFormInputWrapper
    {
        display:        flex;
        flex-direction: column;
        width:          100%;

        .requestMessageFormInputRow
        {
            display:        flex;
            flex-direction: row;
            margin-top:     15px;
            padding-right:  96px;

            .requestMessageFormInputLabel
            {
                min-width:    65px;
                margin-left:  11px;
                margin-right: 20px;
                text-align:   end;
                color:        $colorGrayDarker;
                align-self:   center;
            }

            .requestMessageFormInputValue
            {
                width: 100%;
            }
        }
    }
}
