//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.footerWrapper
{
    background: $colorOrange;
    position:   relative;
    display:    flex;
    flex-flow:  column;
    width:      100%;
    max-width:  100%;
    margin-top: auto;

    &::before
    {
        content:             '';
        background-image:    url('/assets/images/footer-wave.svg');
        height:              85px;
        left:                0;
        top:                 -84px;
        width:               100%;
        position:            absolute;
        background-size:     100% 100%;
        background-repeat:   no-repeat;
        background-position: left bottom;
    }

    &.footerWrapperGrow
    {
        flex: 0 0 calc(50% - 20px);
    }

    .footerContent
    {
        width:       100%;
        max-width:   $screen-lg;
        margin:      auto auto 0 auto;
        padding:     25px 0;
        display:     flex;
        flex-flow:   column;
        align-items: center;

        @media (min-width: $screen-md)
        {
            flex-flow: row nowrap;
        }
    }

    .footerVersion
    {
        width:           100%;
        max-width:       $screen-lg;
        margin:          0 auto;
        padding-bottom:  25px;
        display:         flex;
        justify-content: center;

        span
        {
            padding-left: 10px;
            width:        fit-content;

            @media (min-width: $screen-lg + 20px)
            {
                padding-left: 0;
            }
        }

        @media (min-width: $screen-md)
        {
            justify-content: flex-start;
        }
    }

    .footerMenu
    {
        display:        flex;
        flex-flow:      row wrap;
        align-items:    center;
        list-style:     none;
        text-transform: uppercase;
        padding:        0 10px;

        @media (min-width: $screen-md)
        {
            flex-flow: row nowrap;
        }

        @media (min-width: $screen-lg + 20px)
        {
            padding-left: 0;
        }

        li
        {
            display:        inline;
            font-weight:    $fontWeightSemiBold;
            font-size:      12px;
            margin:         0 22px 0 0;
            letter-spacing: 1px;
            flex:           0 0 100%;
            text-align:     center;

            @media (min-width: $screen-md)
            {
                flex:       inherit;
                text-align: left;
            }

            a
            {
                color:           $colorBlue;
                position:        relative;
                text-decoration: none;

                &::after
                {
                    content:          '';
                    position:         absolute;
                    left:             0;
                    bottom:           -3px;
                    height:           2px;
                    background-color: $colorBlue;
                    width:            100%;
                    opacity:          0;
                    transition:       opacity 0.1s;
                }

                &:hover
                {
                    &::after
                    {
                        opacity: 1;
                    }
                }
            }
        }
    }

    .socialMediaList
    {
        flex:            1;
        align-items:     flex-end;
        justify-content: flex-end;
        text-align:      right;

        @media (max-width: $screen-sm-max)
        {
            margin-top: 20px;

            li
            {
                margin-left: 0;
            }
        }
    }
}
