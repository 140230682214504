//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.wrapper
{
    background-color: $colorGrayLight;
    display:          flex;
    box-sizing:       border-box;
    justify-content:  space-between;
    width:            100%;
    gap:              10px;
    flex-direction:   row;
    padding:          20px;

    .left
    {
        display: flex;
        flex:    2;
    }

    .middle
    {
        display:     flex;
        align-items: center;
    }

    .right
    {
        display: flex;
        flex:    1;
    }

    .projectWrapper
    {
        display:        flex;
        flex-direction: column;

        .projectName
        {
            font-size: 14px;
        }

        .projectRuntimeVolumeWrapper
        {
            margin-top:     10px;
            display:        flex;
            flex-direction: row;
            gap:            10px;
            color:          $colorGrayDark;
            font-size:      12px;

            .projectRuntime
            {

            }

            .projectVolume
            {

            }
        }
    }

    .actions
    {
        flex:            1;
        display:         flex;
        justify-content: flex-end;

        .innerActions
        {
            flex-direction: row;
            gap:            10px;
            display:        flex;
        }
    }

    .match
    {
    }

    .matchText
    {
        margin-left: 24px;
        font-weight: $fontWeightBold;
        font-size:   14px;
    }

    .status
    {
        flex: 1;

        .statusLabel
        {
            font-weight:  $fontWeightBold;
            margin-right: 5px;
        }

        .statusValue
        {
            font-weight: $fontWeightBold;

            &.colorBlack
            {
                color: $colorBlack;
            }

            &.colorOrange
            {
                color: $colorOrange;
            }

            &.colorRed
            {
                color: $colorRed;
            }
        }
    }
}
