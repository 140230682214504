//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.sideMenuFooterButtonWrapper
{
    padding:    0 14px;
    box-sizing: border-box;
    transition: background-color 0.1s linear;
    cursor:     pointer;
    margin:     20px 0 0 0;

    & + .sideMenuFooterButtonWrapper
    {
        margin-top: 0;
    }

    &:hover,
    &.sideMenuFooterButtonWrapperActive
    {
        .sideMenuFooterButtonTitle
        {
            color: $colorBlack;
        }
    }

    .sideMenuFooterButtonTitle
    {
        font-family:    $fontSourceOpenSans;
        font-size:      9px;
        font-weight:    $fontWeightRegular;
        font-stretch:   normal;
        font-style:     normal;
        line-height:    1.11;
        letter-spacing: normal;
        color:          $colorGrayDarker;
        transition:     color 0.1s linear;
    }
}
