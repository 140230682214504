//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.tableContainer
{
    width:     100%;
    position:  relative;
    min-width: fit-content;

    .table
    {
        width:          100%;
        overflow:       hidden;
        border-radius:  2px;
        border-spacing: 0;
    }
}
