//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.requestMessageServiceInfoWrapper
{
    display: flex;

    .requestMessageServiceImage
    {
        width:      70px;
        height:     70px;
        object-fit: contain;

        > div
        {
            border-radius: 0;
        }
    }

    .requestMessageServiceInfos
    {
        margin-left: 15px;

        .requestMessageServiceTitle
        {
            font-size:   14px;
            font-weight: $fontWeightExtraBold;
        }

        .requestMessageServiceDescription
        {
            font-size:  14px;
            margin-top: 5px;
            color:      $colorGrayDarker;
        }
    }
}

.requestMessageServiceWrapper
{
    display:        flex;
    flex-direction: column;
    margin-top:     55px;

    > h4
    {
        text-transform: uppercase;
        margin-bottom:  15px;
    }
}
