//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.companySearchResultWrapper
{
    height:      41px;
    position:    relative;
    padding:     0 0 0 56px;
    font-family: $fontSourceOpenSans;
    font-size:   14px;
    display:     flex;
    align-items: center;

    &:hover
    {
        // TODO: https://lulububu.atlassian.net/browse/SUPPLYDU-88
    }

    .companyLogoWrapper
    {
        position: absolute;
        top:      6px;
        left:     11px;
    }

    .companyTypeWrapper
    {
        $distanceToPipe: 12px;

        font-family:     $fontSourceOpenSans;
        font-size:       12px;
        line-height:     1.25;
        letter-spacing:  normal;
        color:           $colorGrayDarkBlue;
        margin:          0 $distanceToPipe;
        padding:         0 0 0 $distanceToPipe;
        border-left:     1px solid $colorGrayDarkBlue;
    }
}
