//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.factSheetBarChartWrapper
{
    margin:         auto;
    padding-right:  18px;
    padding-left:   22px;
    padding-bottom: 20px;

    canvas
    {
        max-height: 100%;
    }
}
