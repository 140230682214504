//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.headlineLarge
{
    font-family:    $fontHKGrotesk;
    font-size:      26px;
    font-weight:    $fontWeightBold;
    font-stretch:   normal;
    font-style:     normal;
    line-height:    1.25;
    letter-spacing: normal;
    color:          $colorBlackLight;

    &.alignCenter
    {
        text-align: center;
    }

    &.alignLeft
    {
        text-align: left;
    }

    &.alignRight
    {
        text-align: right;
    }
}
