//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.productionPortfolio
{
    width:           100%;
    display:         flex;
    flex-wrap:       wrap;
    gap:             30px;
    justify-content: flex-start;

    .productionPortfolioItem
    {
        width:            100%;
        background-color: $colorWhite;

        @media (min-width: $screen-sm)
        {
            width: calc(50% - 15px);
        }

        .productionPortfolioHeader
        {
            display:         flex;
            justify-content: space-between;

            .productionPortfolioHeaderTitle
            {
                display:        flex;
                flex-direction: column;
                margin-top:     20px;
                margin-left:    25px;
                margin-bottom:  20px;
                font-family:    $fontHKGrotesk;

                .productionPortfolioHeaderTitleText
                {
                    font-size: 20px;
                    color:     $colorBlack;
                }

                .productionPortfolioHeaderTitleCategory
                {
                    font-size: 14px;
                    color:     $colorGrayDarker;
                }
            }

            .productionPortfolioHeaderRequest
            {
                padding-right: 25px;
                padding-top:   25px;
                display:       flex;

                > button
                {
                    height: 30px;

                    &:last-child
                    {
                        margin-left: 10px;
                    }
                }
            }
        }

        .productionPortfolioImageWrapper
        {
            display: flex;

            .productionPortfolioImage
            {
                max-height: 220px;
                width:      100%;
                object-fit: cover;
            }
        }

        .productionPortfolioFooter
        {
            min-height:       80px;
            padding:          20px 25px;
            background-color: $colorWhite;
        }
    }
}
