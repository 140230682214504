//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.tabWrapper
{
    position:        relative;
    display:         flex;
    flex-flow:       row nowrap;
    justify-content: flex-start;
    cursor:          pointer;
    padding:         10px 14px;
    margin:          0 11px;
    min-width:       100px;
    text-align:      center;

    @media (min-width: $screen-md)
    {
        height:          55px;
        padding:         5px 0 10px 0;
        margin-right:    25px;
        justify-content: center;
    }

    h3,
    h3 > span,
    > span
    {
        position: relative;
        z-index:  1;
    }

    > *
    {
        display: inline-block;

        @media (min-width: $screen-md)
        {
            margin-top: 20px;
        }

        @media (max-width: $screen-sm-max)
        {
            margin-bottom: 0;
        }
    }

    .count
    {
        font-family:    $fontSourceOpenSans;
        font-size:      14px;
        font-weight:    $fontWeightRegular;
        font-stretch:   normal;
        font-style:     normal;
        line-height:    1.43;
        letter-spacing: 0.5px;
        text-align:     center;
        color:          $colorBlackLight;
        margin-left:    10px;
    }

    span
    {
        color:      $colorGrayDark !important;
        transition: color 0.1s linear;
    }

    &.tabActive
    {
        span
        {
            color: $colorBlack !important;
        }
    }
}
