//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.alertBox
{
    padding:          25px 29px;
    border-width:     1px;
    border-style:     solid;
    position:         relative;
    background-color: $colorWhite;
    font-size:        14px;
    font-weight:      $fontWeightRegular;
    line-height:      1.43;
    letter-spacing:   0.5px;
    border-radius:    10px;

    + .alertBox
    {
        margin-top: 20px;
    }

    h3
    {
        font-weight:    $fontWeightExtraBold;
        text-transform: uppercase;
        margin-bottom:  6px;
    }

    p
    {
        color: $colorGrayDarker;
    }

    &.alertBoxError
    {
        border-color: $colorRedRoseBud;

        h3
        {
            color: $colorRedCinnabar;
        }

        .alertBoxCloseButtonWrapper
        {
            svg
            {
                *
                {
                    @include svgColor($colorRedCinnabar);
                }
            }
        }
    }

    &.alertBoxSuccess
    {
        border-color: $colorGreenEmerald;

        h3
        {
            color: $colorGreenEmerald;
        }

        .alertBoxCloseButtonWrapper
        {
            svg
            {
                *
                {
                    @include svgColor($colorGreenEmerald);
                }
            }
        }
    }

    &.alertBoxWarning
    {
        border-color: $colorOrange80;

        h3
        {
            color: $colorOrange;
        }

        .alertBoxCloseButtonWrapper
        {
            svg
            {
                *
                {
                    @include svgColor($colorOrange);
                }
            }
        }
    }

    .alertBoxCloseButtonWrapper
    {
        position: absolute;
        top:      5px;
        right:    5px;

        &:hover
        {
            svg
            {
                *
                {
                    opacity: 0.8;
                }
            }
        }

        svg
        {
            width:  12px;
            height: 12px;

            *
            {
                transition: opacity 0.1s linear;
            }
        }
    }
}
