//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.photosPreviewRow
{
    width:     100%;
    margin:    auto auto;
    display:   flex;
    flex-wrap: wrap;

    .photosPreviewImageWrapper
    {
        display:    flex;
        margin-top: 16px;
        flex-flow:  wrap;
    }
}
