//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.machineParameterWrapper
{
    display:      flex;
    flex-flow:    column;
    margin-top:   20px;
    margin-right: 25px;

    &:last-child
    {
        margin-right: 0;
    }

    .machineParameter
    {
        font-family:    $fontSourceOpenSans;
        font-size:      14px;
        font-stretch:   normal;
        font-style:     normal;
        line-height:    1.43;
        letter-spacing: normal;

        &.machineParameterTitle
        {
            color:       $colorGrayDark;
            font-weight: $fontWeightMedium;
        }

        &.machineParameterValue
        {
            color:       $colorBlackLight;
            font-weight: $fontWeightExtraBold;
        }
    }
}
