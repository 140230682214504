//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.modalWrapper
{
    z-index:          $zIndexModalWrapper;
    height:           100%;
    width:            100%;
    position:         fixed;
    top:              0;
    left:             0;
    box-sizing:       border-box;
    padding-bottom:   100px;
    overflow-y:       scroll;
    display:          flex;
    justify-content:  center;
    align-items:      center;
    cursor:           pointer;
    background-color: $colorBlack60;

    &.modalWrapperUnclosable
    {
        cursor: default;
    }

    .modalContainer
    {
        cursor:           default;
        padding:          25px;
        background-color: $colorWhite;

        &.modalContainerTypePrompt,
        &.modalContainerTypeWidePrompt
        {
            max-width:      360px;
            border-radius:  5px;
            margin-top:     100px;
            padding-left:   35px;
            padding-right:  35px;
            padding-bottom: 30px;

            .modalHeader > h2,
            .modalContent
            {
                text-align: center;
            }

            .modalHeader
            {
                margin-bottom: 10px;
            }

            .modalFooter
            {
                margin-top: 25px;

                > *
                {
                    flex: 1;
                }
            }
        }

        &.modalContainerTypeWidePrompt
        {
            min-width: 450px;

            .modalFooter
            {
                margin-top:      25px;
                justify-content: center;

                > button
                {
                    flex:      none;
                    min-width: 130px;
                }
            }
        }

        &.modalContainerTypeFull
        {
            $leftMargin:   311px;

            width:         100%;
            margin:        0 10px;
            max-width:     100%;
            box-sizing:    border-box;
            align-self:    flex-start;
            border-radius: 2px;

            @media (min-width: $screen-md)
            {
                max-width: 1200px - $leftMargin;
                margin:    150px 0 0 0;
            }

            .modalHeader
            {
                margin-bottom: 24px;
            }

            .modalFooter
            {
                padding:    20px 0 5px 0;
                border-top: 1px solid $colorGray;
                margin-top: 25px;
            }
        }

        .modalFooter
        {
            display:   flex;
            flex-flow: row nowrap;

            > *
            {
                margin-right: 10px;

                &:last-child
                {
                    margin-right: 0;
                }
            }
        }
    }
}
