//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.companySearchResults
{
    display:        flex;
    flex-direction: column;
    margin-top:     20px;
    border:         1px solid $colorGrayLight;

    .noResults
    {
        padding: 20px;
        margin:  auto;
        color:   $colorGray;
    }

    .companySearchResult
    {
        display:          flex;
        flex-direction:   row;
        padding:          18px 20px;
        justify-content:  space-between;
        background-color: $colorGrayLight;

        &:nth-child(even)
        {
            background-color: $colorWhite;
        }

        > :first-child
        {
            flex: 1;
        }

        > :nth-child(2)
        {
            flex: 0 0 25%;
        }

        .joinButtonContainer
        {
            display:         flex;
            justify-content: flex-end;
            width:           130px;
        }
    }
}
