//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../styles/all';
@import '../../styles/colors';

.searchResultContainer
{
    margin-top: 29px;
}

.searchTagContainer
{
    display:        flex;
    flex-direction: column;
    align-content:  flex-start;
    flex-wrap:      wrap;
    margin-top:     10px;
    margin-left:    50px;
    margin-bottom:  10px;

    @media (min-width: $screen-sm)
    {
        margin-right: 0;
    }
}

.searchMatchedTag
{
    display:        flex;
    flex-direction: row;
    column-gap:     5px;
    align-items:    center;
    flex-wrap:      wrap;
    row-gap:        2px;
}

.searchNotMatchedTag
{
    color:          $colorGrayDark;
    font-weight:    normal;
    font-size:      12px;
    display:        flex;
    flex-direction: row;
    background:     inherit;
    flex-wrap:      nowrap;
}

.missingTags
{
    text-decoration: line-through;
    margin-left:     5px;
}

.resultsContainer
{
    position: relative;
}
