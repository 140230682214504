//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.claimCompanyHeader
{
    display:         flex;
    flex-direction:  row;
    align-items:     center;
    justify-content: space-between;
    padding:         18px 50px;
    margin-bottom:   30px;
    background:      linear-gradient(263deg, $colorBlueCalypso 100%, $colorBlueNileBlue);

    .claimNowText
    {
        font-family:    $fontHKGrotesk;
        font-size:      30px;
        font-weight:    bold;
        line-height:    0.75;
        color:          $colorOrange;
        text-transform: uppercase;

        > :nth-child(2)
        {
            color: $colorWhite;
        }

        > :not(:last-child)
        {
            margin-right: 8px;
        }
    }

    .logo
    {
        height: 30px;
        flex:   0 0 70%;
    }

    .claimButton,
    .claimNowText
    {
        flex: 0 0 15%;
    }

    .claimButton
    {
        display:         flex;
        flex-direction:  row;
        justify-content: flex-end;

        svg
        {
            path
            {
                @include svgColorFillImportant($colorWhite);
            }
        }
    }
}
